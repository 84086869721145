import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {getCitiesWithCountry, getEthnics, getReligions, getThemes, getProfile,getQuestionsWeb,getCategoriesWithQuestions, addEvent, editEvent,getEventList, getUserEventList, addCoupleEvent } from "../../store/slices/userSlice";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";



const CreateEvent = () => {
  const { t } = useTranslation();
  
  
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const profile = useSelector(getProfile);
  const [load, setload] = useState(false);
  const [load1, setload1] = useState(false);
  
  const curryear= new Date().getFullYear();
  const yearsArray= [curryear,curryear+1,curryear+2,curryear+3]

 
  console.log('myEvents', myEvents)

  const [formData, setFormData] = useState({
    page: 0,
    isquestion: false,
    user_id: location?.state?._id ? location?.state?._id?.user_id:"",
    event_name: location?.state?._id? location?.state?._id?.event_name:"",
    event_city: location?.state?._id? location?.state?._id?.event_city?._id:"",
    show_couple: location?.state?._id?`${location?.state?._id?.person1?.name} & ${location?.state?._id?.person2?.name || "partner"}`:"",
    show_city: location?.state?._id?location?.state?._id?.event_city?.name:"",
    event_color: location?.state?._id?location?.state?._id?.event_color:"#563d7c",
    event_date: location?.state?._id?location?.state?._id?.event_date:"1",
    event_prefered_day: location?.state?._id?location?.state?._id?.event_prefered_day:"1",
    event_prefered_season: location?.state?._id?location?.state?._id?.event_prefered_season:"1",
    event_prefered_year: location?.state?._id?location?.state?._id?.event_prefered_year:"1",
    no_of_guests: location?.state?._id?location?.state?._id?.no_of_guests:"",
    user_budget: location?.state?._id?location?.state?._id?.eventBudget?.event_total:"",
    user_budget_currency: location?.state?._id?location?.state?._id?.user_budget_currency:"SAR",
    prefered_religion: location?.state?._id?location?.state?._id?.prefered_religion:"",
    prefered_services: location?.state?._id?location?.state?._id?.prefered_services:[],
    prefered_typeofservices: location?.state?._id?.prefered_typeofservices?location?.state?._id?.prefered_typeofservices:[],
    sub_services: location?.state?._id?location?.state?._id?.sub_services:[],
    prefered_ethnics: location?.state?._id?location?.state?._id?.prefered_ethnics:"",
    prefered_themes: location?.state?._id?location?.state?._id?.prefered_theme:"",
  });

  const handleFormUpdate = (data) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  };


  console.log('formData', formData)

  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function handleDateChange(e) {
  const inputDate = e.target.value;
  const currentDate = getCurrentDate();

  if (inputDate < currentDate) {
      e.target.value = currentDate; 
  }
}


  const [id, setid] = useState(location?.state?._id?location?.state?._id?._id:"");
  const [services, setServices] = useState(null);
  const [questions, setquestions] = useState([]);
  const [subcategories, setsubcategories] = useState([]);
  const [cities, setCities] = useState(null);
  const [toggle, settoggle] = useState(false);
  const [toggle1, settoggle1] = useState(false);

  const [actualmyEvents, setactualmyEvents] = useState([]);


  const handleSetData = (e) => {
  
    const mergedEvents = [];


    e?.forEach(event => {
    const modifiedEvent = {
        ...event,
        Event: [event.Event]
    };

    const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
    );

    if (existingEventIndex !== -1) {
        mergedEvents[existingEventIndex].Event.push(event.Event);
    } else {
        mergedEvents.push(modifiedEvent);
    }
});

setactualmyEvents(mergedEvents)
// setfilteredData(mergedEvents)
}

useEffect(() => {
    handleSetData(myEvents)
  }, [myEvents]);

console.log('location.state', location.state)
console.log('actualmyevents', actualmyEvents)

const handleColorChange = (e) => {
  handleFormUpdate({ ...formData, event_color: e.target.value });
};

const handleSkillChange = (category) => {
    

  if (
    !formData?.prefered_services?.some(
      (obj) =>
        obj._id === category
    )
  ) {
   
 handleFormUpdate({
  prefered_services: [
        ...formData.prefered_services,
        { _id: category},
      ],
    });
  }
 
 else {

  handleFormUpdate({
    prefered_services: formData.prefered_services.filter(
      (obj) =>
        !(obj._id === category)
    ),
  });
}
};

const handleSkillChange3 = (category) => {
    

  if (
    !formData?.prefered_typeofservices?.some(
      (obj) =>
        obj._id === category
    )
  ) {
   
 handleFormUpdate({
  prefered_typeofservices: [
        ...formData.prefered_typeofservices,
        { _id: category},
      ],
    });
  }
 
 else {

  handleFormUpdate({
    prefered_typeofservices: formData.prefered_typeofservices.filter(
      (obj) =>
        !(obj._id === category)
    ),
  });
}
};


const getQuestions = async () => {
  try {
    
    setquestions(null);
    const response = await dispatch(getQuestionsWeb(formData?.prefered_services)).unwrap();

    console.log("asdassasa", response);
    setquestions(response?.data);
  } catch (rejectedValueOrSerializedError) {}
};


const getSubCategories = async (id) => {
  try {
  
    // setload(true)
    const response = await axios.post(`user/getsubcategories`, {id: formData?.prefered_services, type: "search", lang: 'en'});
    setsubcategories(response?.data?.data[0]?.subcategories);
    // setload(false)

  } catch (rejectedValueOrSerializedError) {}
};



const handleSkillChange1 = (event, id, type, removeradioids) => {
  let updatedSubServices = [...formData.sub_services];
  
  if (type === "radio") {
    const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === id);
    if (existingObjectIndex !== -1) {
      let idsFinal = removeradioids?.map((id) => id._id);
      const ids = idsFinal.filter((service) => service !== event);
      const existingObject = updatedSubServices[existingObjectIndex];
      existingObject.item = existingObject.item.filter(itemId => !ids.includes(itemId));

      if (!existingObject.item.includes(event)) {
        existingObject.item.push(event);
      }

      if (existingObject.item.length === 0) {
        updatedSubServices.splice(existingObjectIndex, 1);
      }
    } else {
      updatedSubServices.push({ _id: id, item: [event] });
    }
  } else {
    const existingObjectIndex = updatedSubServices.findIndex((obj) => obj._id === id);

    if (existingObjectIndex !== -1) {
      const existingObject = updatedSubServices[existingObjectIndex];
      const eventIndex = existingObject.item.indexOf(event);

      if (eventIndex !== -1) {
        existingObject.item.splice(eventIndex, 1);

        if (existingObject.item.length === 0) {
          updatedSubServices.splice(existingObjectIndex, 1);
        }
      } else {
        existingObject.item.push(event);
      }
    } else {
      updatedSubServices.push({ _id: id, item: [event] });
    }
  }

  handleFormUpdate({
    sub_services: updatedSubServices,
  });
};



  const Services = async () => {
    try {
      setload(true);
      setServices(null);
      const response = await dispatch(getCategoriesWithQuestions()).unwrap();
      console.log("abcddddddddddd", response?.data);
      setServices(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  function moveObjectToFirst(array, objectId) {
    const index = array.findIndex(item => item.countryId === objectId);
  
    if (index !== -1) {
      const objectToMove = array.splice(index, 1)[0];
      array.unshift(objectToMove); 
    }
  }

  const CitiesWithCountry = async () => {
    try {
      setload(true);
      setCities(null);
      const response = await dispatch(getCitiesWithCountry()).unwrap();
      console.log("abc", response?.data);
      setCities(response?.data);
      moveObjectToFirst(response?.data, profile?.country);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  useEffect(() => {
    let mount = true;
    if (mount) {
      handleFormUpdate({ isquestion: false })
      Services();
      CitiesWithCountry();
    }
    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    
  
      getQuestions();
      getSubCategories();
    
  
  }, [formData?.prefered_services]);


  const [ethnics, setEthnics] = useState(null);
  const [religions, setreligions] = useState(null);
  const [themes, setthemes] = useState(null);
  


  const Ethnics = async () => {
    try {
      setload(true);
      setEthnics(null);
      const response = await dispatch(getEthnics()).unwrap();
      console.log("abc", response?.data);
      setEthnics(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Religions = async () => {
    try {
      setload(true);
      setreligions(null);
      const response = await dispatch(getReligions()).unwrap();
      console.log("abc", response?.data);
      setreligions(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };
  const Themes = async () => {
    try {
      setload(true);
      setthemes(null);
      const response = await dispatch(getThemes()).unwrap();
      console.log("abc", response?.data);
      setthemes(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {
      Ethnics();
      Religions();
      Themes();
    }
    return () => {
      mount = false;
    };
  }, []);


  const select_color = [
    {
      id: 1,
      color_code: '#ffca3a',
    },
    {
      id: 2,
      color_code: '#8ac926',
    },
    {
      id: 3,
      color_code: '#1982c5',
    },
    {
      id: 4,
      color_code: '#FF4365',
    },
    {
      id: 5,
      color_code: '#ec7d10',
    },
  ];
  const daysOptions = [
    { value: 'weekdays', label: 'Weekdays' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' },
  ];
  const seasonsOptions = [
    { value: 'spring', label: 'Spring', image: '../assets/images/spring.svg' },
    { value: 'summer', label: 'Summer', image: '../assets/images/summer.svg' },
    { value: 'fall', label: 'Fall', image: '../assets/images/fall.svg' },
    { value: 'winter', label: 'Winter', image: '../assets/images/winter.svg' },
  ];
  const guestAmountOptions = [
    "Less than 50",
    "50-200",
    "200-350",
    "350-500",
    "500-650",
    "450-800",
    "800-950",
    "More than 1000",
    "UNDECIDED",
  ];

  const weddingCosts = ['1,00,000', '1,50,000',
'2,00,000',  '2,50,000',
'3,00,000',  '3,50,000',
'4,00,000',  '4,50,000',
'5,00,000',  '5,50,000',
'6,00,000',  '6,50,000',
'7,00,000',  '7,50,000',
'8,00,000',  '8,50,000',
'9,00,000',  '9,50,000',
'10,00,000'];


console.log('weddingCosts', formData?.user_budget)

const handleButtonClick = async () => {
  try {
    setload1(true);
    const currentDate = getCurrentDate();
    
    if(formData.event_date=="1"&&formData.event_prefered_day=="1"&&formData.event_prefered_season=="1"&&formData.event_prefered_year=="1"){
      toast.error("Please select your desired date")
    } 
    else if (formData.event_date!=="" && formData.event_date!=="1" && formData.event_date < currentDate) {
      toast.error("Invalid date") 
    }

    else{
          

    if(id){

      await dispatch(
          editEvent({
            event_id: id,
            event_name: formData.event_name,
            event_color: formData.event_color,
            event_city: formData.event_city,
            event_date: formData.event_date,
            event_prefered_day: formData.event_prefered_day,
            event_prefered_season: formData.event_prefered_season,
            event_prefered_year: formData.event_prefered_year,
            no_of_guests: formData.no_of_guests,
            user_budget: parseInt(formData.user_budget),
            user_budget_currency: formData.user_budget_currency,
            prefered_services: JSON.stringify(
                formData.prefered_services
              ),
                            
              prefered_typeofservices: JSON.stringify(
                formData.prefered_typeofservices
              ),
                            
            sub_services: JSON.stringify(formData.sub_services),
            prefered_religion: formData.prefered_religion,
            prefered_ethnics: formData.prefered_ethnics,
            prefered_themes: formData.prefered_themes,
            role: profile?.role
          })
        ).unwrap();

      }
      else{
        await dispatch(
          addEvent({
            person1: profile!=="planner"?myEvents?.length>0&&myEvents[0]?.person1?._id:location?.state?._id?.person1?._id,
            person2: profile!=="planner"?myEvents?.length>0&&myEvents[0]?.person2?._id:location?.state?._id?.person2?._id,
            // user_id: formData?.user_id,
            event_name: formData.event_name,
            event_color: formData.event_color,
            event_city: formData.event_city,
            event_date: formData.event_date,
            event_prefered_day: formData.event_prefered_day,
            event_prefered_season: formData.event_prefered_season,
            event_prefered_year: formData.event_prefered_year,
            no_of_guests: formData.no_of_guests,
            user_budget: parseInt(formData.user_budget),
            user_budget_currency: formData.user_budget_currency,
            prefered_services: JSON.stringify(
                formData.prefered_services
              ),              
            prefered_typeofservices: JSON.stringify(
                formData.prefered_typeofservices
              ),              
            sub_services: JSON.stringify(formData.sub_services),
            prefered_religion: formData.prefered_religion,
            prefered_ethnics: formData.prefered_ethnics,
            prefered_themes: formData.prefered_themes,
            role: "customer"
          })

        ).unwrap();
      }
      await dispatch(getUserEventList())
      navigate(profile?.role=="customer"?'/dashboard':'/dashboard-planner');
    }
  } catch (error) {
    console.error(error);
  } finally {
    setload1(false); 
  }
};

const handleButtonClickPlanner = async () => {
  try {
    setload1(true);
    const currentDate = getCurrentDate();
    
    if(formData.event_date=="1"&&formData.event_prefered_day=="1"&&formData.event_prefered_season=="1"&&formData.event_prefered_year=="1"){
      toast.error("Please select your desired date")
    } 
    else if (formData.event_date!=="" && formData.event_date!=="1" && formData.event_date < currentDate) {
      toast.error("Invalid date") 
    }

    else{
          

    if(id){

      await dispatch(
          editEvent({
            event_id: id,
            event_name: formData.event_name,
            event_color: formData.event_color,
            event_city: formData.event_city,
            event_date: formData.event_date,
            event_prefered_day: formData.event_prefered_day,
            event_prefered_season: formData.event_prefered_season,
            event_prefered_year: formData.event_prefered_year,
            no_of_guests: formData.no_of_guests,
            user_budget: parseInt(formData.user_budget),
            user_budget_currency: formData.user_budget_currency,
            prefered_services: JSON.stringify(
                formData.prefered_services
              ),
                            
              prefered_typeofservices: JSON.stringify(
                formData.prefered_typeofservices
              ),
                            
            sub_services: JSON.stringify(formData.sub_services),
            prefered_religion: formData.prefered_religion,
            prefered_ethnics: formData.prefered_ethnics,
            prefered_themes: formData.prefered_themes,
            role: profile?.role
          })
        ).unwrap();

      }
      else{
        await dispatch(
          addCoupleEvent({
            person1: profile!=="planner"?myEvents?.length>0&&myEvents[0]?.person1?._id:location?.state?._id?.person1?._id,
            person2: profile!=="planner"?myEvents?.length>0&&myEvents[0]?.person2?._id:location?.state?._id?.person2?._id,
            coupleId: formData?.user_id||myEvents[0]?._id,
            event_name: formData.event_name,
            event_color: formData.event_color,
            event_city: formData.event_city,
            event_date: formData.event_date,
            event_prefered_day: formData.event_prefered_day,
            event_prefered_season: formData.event_prefered_season,
            event_prefered_year: formData.event_prefered_year,
            no_of_guests: formData.no_of_guests,
            user_budget: parseInt(formData.user_budget),
            user_budget_currency: formData.user_budget_currency,
            prefered_services: JSON.stringify(
                formData.prefered_services
              ),              
            prefered_typeofservices: JSON.stringify(
                formData.prefered_typeofservices
              ),              
            sub_services: JSON.stringify(formData.sub_services),
            prefered_religion: formData.prefered_religion,
            prefered_ethnics: formData.prefered_ethnics,
            prefered_themes: formData.prefered_themes,
            role: "customer"
          })

        ).unwrap();
      }
      await dispatch(getUserEventList())
      navigate('/dashboard');
    }
  } catch (error) {
    console.error(error);
  } finally {
    setload1(false); 
  }
};


console.log('questions', questions)
  return (
    <section className="wf-create-event px-5 pb-5 pt-2">
        <div className="">
        <h3 className="font-size-20 dm-font pink-color mb-3">{t('LetsCreateYourEvent')}!</h3>
        <div className="row gx-5 mb-4 gy-3">
        <div className="col-md-6">
        <div class="dropdown wf-event-name-dropdown">
        <div class="form-floating wf-input wf-input-101">
              <input onChange={(e)=>handleFormUpdate({ event_name: e.target.value})} defaultValue={formData?.event_name} type="text" class="form-control" id="floatingInput" placeholder="Event name" 
              autocomplete="event name" required />
              <label for="floatingInput">{t('Event')} {t('Name')}</label>
          </div>
          <a class="wf-event-color-dropdown" style={{backgroundColor:`${formData.event_color}`}} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
          <div class="dropdown-menu">
            <div className="d-flex gap-2 wf-event-color">
            {select_color?.map((color) => (
                <div key={color.id}>
                  <input type="radio" className="btn-check" id={`${color.id}-color`} name="color101" onChange={handleColorChange} value={color.color_code} checked={formData.event_color === color.color_code} />
                  <label className="btn btn-md" style={{ backgroundColor: `${color.color_code}` }} htmlFor={`${color.id}-color`}></label>
                </div>
              ))}
            </div>
          
          </div>
        </div>
        
        </div>
        <div className="col-md-6">
        <div onClick={()=>settoggle(!toggle)}>
          <div class="dropdown country-city-dropdown">
          <a class="btn btn-light dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {formData?.show_city?formData?.show_city:<>{t('City')}</>}
          </a>

          <ul className={`dropdown-menu ${toggle&&"show"}`}>
          {cities?.length>0&&cities.map((c)=>(<li className="country-city cursor-pointer"><img src={`${process.env.REACT_APP_IMGURL}${c.countryFlag}`} width="25" /><span style={{"margin-left": "10px"}}>{c.countryName}</span>
          <ul className="city-list show">
              {c.cities.map((ci)=>(<li onClick={()=>handleFormUpdate({ event_city: ci.id, show_city: ci.name})} className={`city cursor-pointer ${formData?.show_city==ci.name&&"selected" }`}><a>{ci.name}</a></li>))}                    
          </ul>
          </li>))} 
          </ul>

          </div>
          </div>
        </div>
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-3">{t('Select')} {t('your')} {t('Categories')}</h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(22).fill(null).map((_, index) => (
            <CaregoriesButtonSkeleton key={index} />
          )):services?.length>0&&services?.map((s)=>(
          <>
          <input type="checkbox" name="cat-select" value={s._id} checked={formData?.prefered_services?.some((obj) => obj._id.toString() === s._id.toString())}
          onChange={() => handleSkillChange(s?._id)} id={s?._id} class="btn-check" />
          <label key={s._id} class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{width:'10%', lineHeight:'1.2'}} for={s?._id}>
            <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} className="mx-auto d-block color" height="35" />
            <img src={`${process.env.REACT_APP_IMGURL}white-${s.imageName}`} className="mx-auto d-block white" height="35" />
            {s?.name}</label>
          </>
          ))}
        </div>


        {profile?.role=="planner"&&!id&&(<><h3 className="font-size-20 dm-font pink-color mb-3">{t('Couples')}</h3>
        <div className="col-md-6 mb-4">
        <div onClick={()=>settoggle1(!toggle1)}>
          <div class="dropdown country-city-dropdown">
          <a class="btn btn-light dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {formData?.show_couple?formData?.show_couple:<>{t('Couples')}</>}
          </a>

          <ul className={`dropdown-menu ${toggle1&&"show"}`}>
          <li className="country-city cursor-pointer">
          <ul className="city-list show">
          {actualmyEvents?.length>0&&actualmyEvents?.map((c)=>(<li onClick={()=>handleFormUpdate({ user_id: c._id, show_couple: `${c?.person1?.name} & ${c?.person2?.name || "partner"}`})} className={`city cursor-pointer ${formData?.user_id==c._id&&"selected" }`}><a>{c?.person1?.name} & {c?.person2?.name || "partner"}</a></li>))}                    
          </ul>
          </li>
          </ul>

          </div>
          </div>
        </div></>)}


        <h3 className="font-size-20 dm-font pink-color mb-3">{t('WhenisyourWedding')}?</h3>
        <div className="col-md-4 mb-4">
          <div class="input-group wf-input-group wf-shadow-2">
            <div class="form-floating wf-input">
              <input onBlur={handleDateChange} min={getCurrentDate()} value={!formData?.event_date==""||!formData?.event_date=="1"?formData?.event_date:""} onChange={(e)=>handleFormUpdate({ event_date: e.target.value, event_prefered_day:"", event_prefered_season:"", event_prefered_year:""})} type="date" class="form-control" id="floatingInput" placeholder="" 
              autocomplete="date" required />
              <label className="purple-color" for="floatingInput">{t('FixedDate')}</label>
            </div>
            <button onClick={()=>handleFormUpdate({ event_date: ""})} class="input-group-text pink-color"><i class="fas fa-times"></i></button>
          </div>
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('NotSure')}? {t('Giveusahint')}!</h3>
        <div className="row mb-4">
        <div className="col-md-4">
          <p className="font-size-20 dm-font purple-color mb-2">{t('Select')} {t('Day')}</p>
          <div class="d-flex gap-2 flex-wrap">
            {daysOptions.map((day) => (
            <>
            <input type="radio" disabled={formData.event_date === '1' || formData.event_date === '' ? false : true} checked={formData.event_prefered_day === day.value}
            onChange={() => handleFormUpdate({ event_prefered_day: day.value })} name="day-options" id={day.value} class="btn-check" />
            <label class="btn wf-select-btn" for={day.value}>{day.label}</label>
            </>
            ))}
          </div>
        </div>
        <div className="col-md-4">
          <p className="font-size-20 dm-font purple-color mb-2">{t('Select')} {t('Season')}</p>
          <div class="d-flex gap-2 flex-wrap">
          {seasonsOptions.map((season) => (
            <>
            <input type="radio" disabled={formData.event_date === '1' || formData.event_date === '' ? false : true} checked={formData.event_prefered_season === season.value}
            onChange={() => handleFormUpdate({ event_prefered_season: season.value })} name="season-options" id={season.value} class="btn-check" />
            <label class="btn wf-select-btn" for={season.value}>{season.label}</label>
            </>
          ))}
          </div>
        </div>
        <div className="col-md-4">
          <p className="font-size-20 dm-font purple-color mb-2">{t('Select')} {t('Year')}</p>
          <div class="d-flex gap-2 flex-wrap">
          {yearsArray?.map((y)=>(
            <>
            <input type="radio" disabled={formData.event_date=="1"||formData.event_date==""?false:true} checked={formData.event_prefered_year==y} 
            onChange={()=>handleFormUpdate({ event_prefered_year: y})} name="year-options" id={y} class="btn-check" />
            <label class="btn wf-select-btn" for={y}>{y}</label>
            </>
          ))}
          </div>
        </div>
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('Nohint')}? </h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
            <input type="radio" checked={formData.event_prefered_season=="" && formData.event_prefered_day=="" && formData.event_prefered_year=="" && formData.event_date==""}
            onChange={()=>handleFormUpdate({ event_prefered_year: "", event_prefered_day: "", event_prefered_season:"",event_date:""})}  name="undecided" id="undecided" class="btn-check" />
            <label class="btn wf-select-btn" for="undecided">UNDECIDED</label>
        </div>



        {/* <h3 className="font-size-20 dm-font pink-color mb-3">{t('Select')} {t('your')} {t('SubCategories')}</h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(22).fill(null).map((_, index) => (
            <CaregoriesButtonSkeleton key={index} />
          )):subcategories?.length>0&&subcategories?.map((s)=>(
          <>
          <input type="checkbox" name="cat-select" value={s._id} checked={formData?.prefered_typeofservices?.some((obj) => obj._id.toString() === s._id.toString())}
          onChange={() => handleSkillChange3(s?._id)} id={s?._id} class="btn-check" />
          <label key={s._id} class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{width:'10%', lineHeight:'1.2'}} for={s?._id}>
            <img src={`${process.env.REACT_APP_IMGURL}${s.imageName}`} className="mx-auto d-block color" height="35" />
            <img src={`${process.env.REACT_APP_IMGURL}white-${s.imageName}`} className="mx-auto d-block white" height="35" />
            {s?.name}</label>
          </>
          ))}
        </div> */}



        {questions && questions?.map((question, index) => (
  <div key={question._id}>
    <p className="font-size-20 dm-font pink-color text-capitalize mb-2">{question?.questionCustomer}</p>
    <div className="d-flex gap-2 flex-wrap mb-4">
  {question?.options?.map((option) => (
    <React.Fragment key={option._id}>
      <input
        type={question.type}
        value={option._id}
        checked={formData?.sub_services?.some((obj) => obj._id === question._id && obj.item?.includes(option._id))}
        onClick={() => handleSkillChange1(option._id, question._id, question.type, question.options)}
        id={`option-${question._id}-${option._id}`}
        name={`questions-${question._id}`}
        className="btn-check"
      />
      <label
        className="btn wf-select-btn d-flex flex-column justify-content-center gap-2"
        style={{ width: '100px' }}
        htmlFor={`option-${question._id}-${option._id}`}
      >
        <img
          src={`${process.env.REACT_APP_IMGURL}${option?.imageName}`}
          className="mx-auto d-block color"
          height="35"
          alt={option.name}
        />
        <img
          src={`${process.env.REACT_APP_IMGURL}white-${option?.imageName}`}
          className="mx-auto d-block white"
          height="35"
          alt={option.name}
        />
        {option.name}
      </label>
    </React.Fragment>
  ))}
</div>
   
  </div>
))}


        <h3 className="font-size-20 dm-font pink-color mb-2">{t('ReligiousPreference')}</h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(5).fill(null).map((_, index) => (
            <CaregoriesButtonSkeleton key={index} />
          )):religions?.length>0&&religions.map((t)=>(
          <>
          <input type="radio" checked={formData.prefered_religion==t._id} onChange={(e)=>handleFormUpdate({ prefered_religion: t._id})} id={t._id} name="religion" class="btn-check" />
          <label key={t._id} class="btn wf-select-btn d-flex flex-column justify-content-center gap-2" style={{width:'100px'}} for={t._id}>
            <img src={`${process.env.REACT_APP_IMGURL}${t?.imageName}`} className="mx-auto d-block color" height="35" />
            <img src={`${process.env.REACT_APP_IMGURL}white-${t?.imageName}`} className="mx-auto d-block white" height="35" />
            {t.name}</label>
          </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('Ethnicinfluence')}</h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(8).fill(null).map((_, index) => (
            <ButtonSkeleton key={index} />
          )):ethnics?.length>0&&ethnics.map((t)=>(
            <>
            <input type="radio" checked={formData.prefered_ethnics==t._id} onChange={(e)=>handleFormUpdate({ prefered_ethnics:  t._id})} name="ehtnic-option" id={t._id} class="btn-check" />
            <label class="btn wf-select-btn" for={t._id}>{t.name}</label>
            </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('Weddingtheme')}</h3>
        <div class="d-flex gap-2 flex-wrap mb-4">
          {load? new Array(8).fill(null).map((_, index) => (
            <ButtonSkeleton key={index} />
          )):themes?.length>0&&themes.map((t)=>(
            <>
            <input type="radio" checked={formData.prefered_themes==t._id} onChange={(e)=>handleFormUpdate({ prefered_themes:  t._id})} name="themes-option" id={t.name} class="btn-check" />
            <label class="btn wf-select-btn" for={t.name}>{t.name}</label>
            </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('ExpectedGuests')}?</h3>
        <p className="font-size-14 purple-color mb-2">{t('HowmanyguestsarecomingatyourEvent')}?</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
        {guestAmountOptions.map((option) => (
            <>
            <input type="radio" checked={formData.no_of_guests === option} onChange={() => handleFormUpdate({ no_of_guests: option })} name="expected-guests" id={option.split(" ").join("-")} class="btn-check" />
            <label class="btn wf-select-btn" for={option.split(" ").join("-")}>{option}</label>
            </>
          ))}
        </div>

        <h3 className="font-size-20 dm-font pink-color mb-2">{t('EstimatedBudget')}?</h3>
        <p className="font-size-14 purple-color mb-2">{t('Howmuchareyouplanningtospend')}?</p>
        <div class="d-flex gap-2 flex-wrap mb-4">
        {weddingCosts?.length>0&&weddingCosts?.map((cost, index) => {
           console.log('formData.user_budget', formData.user_budget == cost.replace(/,/g, ''))
          
           const costUpdated=cost.replace(/,/g, '')
           return(<>
          <input
          type="radio"
          checked={formData.user_budget === costUpdated}
          onChange={(e) => handleFormUpdate({ user_budget: e.target.value })} id={`budget-${index}`} value={costUpdated} name="estimated-budget" className="btn-check"/>
        <label className="btn wf-select-btn" for={`budget-${index}`}>{cost} SAR</label>
        </>)})}
            <input checked={formData.user_budget === 'none'} onChange={() => handleFormUpdate({ user_budget: 'none' })} type="radio" name="estimated-budget" id="undecided1" class="btn-check" />
            <label class="btn wf-select-btn" for="undecided1">Undecided</label>
            <input type="radio" checked={formData.user_budget === 'other'} onChange={() => handleFormUpdate({ user_budget: 'other' })} name="estimated-budget" id="other" class="btn-check" />
            <label class="btn wf-select-btn" for="other">Other</label>
        </div>
        {!weddingCosts.map(cost => cost.replace(/,/g, '')).includes(formData.user_budget) && formData.user_budget !== "none" && formData.user_budget !== "" && (
  <div class="input-group wf-input-group wf-shadow-2 mb-4">
    <div class="form-floating wf-input">
      <input
        value={formData.user_budget}
        onChange={(e) => {
          const enteredValue = parseInt(e.target.value);
         
            handleFormUpdate({ user_budget: enteredValue });
         
        }}
        type="number"
        class="form-control"
        id="floatingInput"
        placeholder=""
        autocomplete="date"
        min={0}
        required
      />
      <label className="purple-color" for="floatingInput">
        {t('FixedBudget')}
      </label>
    </div>
    <button onClick={() => handleFormUpdate({ user_budget: "" })} class="input-group-text pink-color">
      <i class="fas fa-times"></i>
    </button>
  </div>
)}
        
        
        <button className="btn wf-btn-2 wf-purple-btn py-2 px-4 rounded-2" onClick={handleButtonClickPlanner}  disabled={load1} >
        {load1 ? (
          <div className="spinner-border wf-spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          id ? <>{t('Update')}</> : <>{t('Save')}</> 
        )}
      </button>


  </div>
    </section>
  )
}

export default CreateEvent