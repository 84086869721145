import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getProfile, getEventList, getUserEventList } from "../../store/slices/userSlice";
import moment from "moment";
import NoEventUI from '../UserDashboard/noEventUI';
import { useTranslation } from "react-i18next";
import AddCouple from "./AddCouple";
import Modal from "react-modal";
const customStyles = {
    content: {
        "--bs-modal-width": "35%",
        display: "block",
    },
};
Modal.setAppElement("#root");




const CoupleList = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const profile = useSelector(getProfile);
    const myEvents = useSelector(getEventList);
    const dispatch = useDispatch();
    const [filteredData, setfilteredData] = useState();
    const [actualmyEvents, setactualmyEvents] = useState([]);
    const [toggle, settoggle] = useState([]);
    const [search, setsearch] = useState("");

    const [modalIsOpen, setIsOpen] = useState(false);



    const handleSetData = (e) => {

        const mergedEvents = [];


        e?.forEach(event => {
            const modifiedEvent = {
                ...event,
                Event: [event?.Event]
            };

            const existingEventIndex = mergedEvents?.findIndex(
                mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
            );

            if (existingEventIndex !== -1) {
                mergedEvents[existingEventIndex].Event.push(event.Event);
            } else {
                mergedEvents.push(modifiedEvent);
            }
        });
        console.log('mergedEvents', mergedEvents)
        setactualmyEvents(mergedEvents)
        setfilteredData(mergedEvents)
    }

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const currentDate = getCurrentDate();


    useEffect(() => {
        handleSetData(myEvents)
    }, [myEvents]);



    useEffect(() => {
        if (search === "") {
            setfilteredData(actualmyEvents);
        } else {
            const filteredEvents = actualmyEvents.filter((event) => {
                const person1Name = event.person1?.name?.toLowerCase() || "";
                const person2Name = event.person2?.name?.toLowerCase() || "";
                const searchText = search.toLowerCase();

                return person1Name.includes(searchText) || person2Name.includes(searchText);
            });
            setfilteredData(filteredEvents);
        }
    }, [actualmyEvents, search]);



    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    const refreshEventList = async () => {
        try {
            console.log("hereeeeeeeeee");
            await dispatch(getUserEventList()).unwrap();
        } catch (error) {
            console.error("Error fetching event list:", error);
        }
    };


    const handleToggleChange = (id) => {
        console.log('idddddddddddddddd', id);
        if (!toggle.includes(id)) {
            settoggle([...toggle, id]);
        } else {
            const updatedEventIds = toggle.filter((itemId) => itemId !== id);
            settoggle(updatedEventIds);
        }
    };


    console.log('filteredData', filteredData)

    return (
        <>


            <section className='wf-user-dashboard wf-background px-md-5 pb-5 pt-2'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            {/* Row 1 */}
                            <div className='row mb-4'>
                                <div className='col-7'>
                                        <h2 class="font-size-36 dm-font purple-color mb-0">Couples</h2>
                                </div>
                                <div className='col-5 text-md-end'>
                                    <div class="d-flex align-items-center justify-content-end gap-4 mb-0">
                                        {/* <a class="font-size-20 purple-color  mb-0" href="#">
                                    <i class="bi bi-filter me-1"></i>
                                    Sort by</a> */}
                                        <button type="button" class="btn wf-btn-2 wf-pink-btn py-2 rounded-2" onClick={() => openModal()}>+ Add New</button>
                                    </div>
                                </div>

                            </div>
                            {/* Row 2*/}
                            {filteredData?.length > 0 && (<div className='row mb-4'>
                                <div className='col-md-6'>
                                    <div class="mb-2">
                                        <h2 class="font-size-30 dm-font pink-color mb-0">Search</h2>
                                    </div>
                                    <div class="input-group recent-couples-search mb-1 wf-shadow-2 rounded">
                                        <input onChange={(e) => setsearch(e.target.value)} type="text" class="form-control" placeholder="Search Couples..." />
                                        <button class="input-group-text purple-color" id="serach-couples">
                                            <i class="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-6 text-md-end'></div>
                            </div>)}

                            <div className='row mb-4'>
                                <div className='col-12'>
                                    <div className='wf-shadow-2 white-bg rounded px-3 py-3 row-gap-3 d-flex flex-column'>


                                        {filteredData?.length > 0 ? filteredData?.map((e) => (
                                            <>
                                                <div className='d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-md-center gap-2'>
                                                    <div className='wf-up-avatar d-flex mb-2' style={{ width: "100px" }}>
                                                        <div className='avatar-couple-first'>
                                                            <img width="57" height="57" alt="" src={e?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.person1?.imageName}` : "https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                                                        </div>
                                                        <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                                                            <img width="57" height="57" alt="" src={e?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.person2?.imageName}` : "https://images.weddified.com/images/50.40971272585057683IMG_20191108_183327.jpg"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "200px" }}>
                                                        <h3 className="font-size-16 fw-bold purple-color mb-1 text-break">{e?.person1?.name || ""}<span className='font-size-12 fw-normal color-grey ms-1'>{e?.person1?.user_purpose || ""}</span></h3>
                                                        <p className='font-size-12 purple-color mb-1 text-break'>{e?.person1?.phone || "Contact not specified"}
                                                            <br />{e?.person1?.email || "Email not specified"}
                                                        </p>
                                                    </div>
                                                    <div style={{ width: "200px" }}>
                                                        <h3 className="font-size-16 fw-bold pink-color mb-1 text-break">{e?.person2?.name || "partner"}<span className='font-size-12 fw-normal color-grey ms-1'>{e?.person2?.user_purpose ? e?.person2?.user_purpose : e?.person1?.user_purpose == "groom" ? "bride" : "groom"}</span></h3>
                                                        <p className='font-size-12 pink-color mb-1 text-break'>{e?.person2?.phone || "Contact not specified"}
                                                            <br />{e?.person2?.email || "Email not specified"}
                                                        </p>
                                                    </div>
                                                    {e?.Event?.some(event => event?.Event?._id) && (<div style={{ width: "60px" }}>
                                                        <h3 className="font-size-12 fw-medium purple-color mb-1 cursor-pointer text-center" onClick={() => navigate("/couples-overview", { state: { data: e } })}><u>Overview</u></h3>
                                                    </div>)}
                                                    <div className='d-flex flex-wrap justify-content-between align-items-center gap-3 text-center' style={{ maxWidth: "400px", width: "100%" }}>
                                                        <div className='wf-couple-update'>
                                                            <h3 className="font-size-16 fw-medium color-black mb-1">{e?.Event?.reduce((acc, obj) => acc + obj?.eventTasks?.length, 0) || 0}</h3>
                                                            <p className="font-size-12 pink-color mb-1"><u>Tasks</u></p>
                                                        </div>
                                                        <div className='wf-couple-update'>
                                                            <h3 className="font-size-16 fw-medium color-black mb-1">{e?.Event?.eventBudget && e?.Event?.eventBudget[0]?.user_budget_currency || ""} {e?.Event?.reduce((acc, obj) => acc + obj?.eventBudget?.event_total, 0) || 0}</h3>
                                                            <p className="font-size-12 pink-color mb-1"><u>Budget</u></p>
                                                        </div>
                                                        <div className='wf-couple-update'>
                                                            <h3 className="font-size-16 fw-medium color-black mb-1">{e?.Event?.reduce((acc, obj) => {
                                                                const sumOfBInA = obj?.eventGuests?.reduce((sum, item) => sum + item.male_count + item.female_count + item.child_count, 0) || 0;
                                                                return acc + sumOfBInA;
                                                            }, 0)}</h3>
                                                            <p className="font-size-12 pink-color mb-1"><u>Guests</u></p>
                                                        </div>
                                                        <div className='wf-couple-update'>
                                                            <h3 className="font-size-16 fw-medium color-black mb-1">{e?.Event?.reduce((acc, obj) => acc + obj?.eventFavourites?.length, 0) || 0}</h3>
                                                            <p className="font-size-12 pink-color mb-1"><u>Shortlist</u></p>
                                                        </div>
                                                        <div className='wf-couple-update'>
                                                            <h3 className="font-size-16 fw-medium color-black mb-1">
                                                                {e?.Event?.reduce((acc, obj) => acc + (!isNaN(obj?.eventBookings) ? obj?.eventBookings?.length : 0), 0) || 0}
                                                            </h3>
                                                            <p className="font-size-12 pink-color mb-1"><u>Booking</u></p>
                                                        </div>
                                                    </div>
                                                    {/* <div className='d-flex gap-4'>
                                        <div className='wf-couplenotification'>
                                            <div className='custom-dropdown notification'>
                                                <a class="wf-notifymessages" href="#">
                                                    <i class="far fa-bell icon-notifications"></i>
                                                    <span class="number">5</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='wf-couplemessage'>
                                            <div className='custom-dropdown notification'>
                                                <a class="wf-notifymessages" href="#">
                                                    <i class="far fa-envelope icon-notifications"></i>
                                                    <span class="number">5</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                                    <div className='totalevents d-flex justify-content-between align-items-center gap-2'>
                                                        <div className='wf-totalevents text-end'>
                                                            <h3 className="font-size-12 fw-medium color-black mb-1">Total Events <span>{Array.isArray(e?.Event) && e.Event.some(event => event?._id) ? e.Event.filter(event => event?._id).length : 0}</span></h3>
                                                            <h3 className="font-size-12 fw-medium color-black mb-1">Upcoming Events <span>{Array.isArray(e?.Event) && e.Event.some(event => event?._id) ? e.Event.filter(event => event?.event_date > currentDate).length : 0}</span></h3>
                                                        </div>
                                                        {Array.isArray(e?.Event) && e.Event.some(event => event?._id) && (<div onClick={() => handleToggleChange(e?._id)} className='color-grey cursor-pointer'>
                                                            <i class={!toggle?.includes(e?._id) ? "bi bi-chevron-down ms-2" : "bi bi-chevron-up ms-2"}></i>
                                                        </div>)}
                                                    </div>
                                                </div>

                                                {toggle?.includes(e?._id) && (<div className='row justify-content-center'>

                                                    {Array.isArray(e?.Event) && e.Event.some(event => event?._id) && e?.Event?.map((evv) => (<div className='col-md-10'>
                                                        <div className='wf-shadow-2 white-bg rounded px-3 py-3 my-2'>
                                                            <div className='d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-md-center gap-2'>
                                                                <div className='d-flex' style={{ width: "200px" }}>
                                                                    <div className='wf-event-name'>
                                                                        <p className="font-size-12 fw-medium color-black me-3 mb-0">{evv?.event_name}</p>
                                                                        <span className='eventline nikah'></span>
                                                                    </div>
                                                                </div>
                                                                <div className='' style={{ width: "200px" }}>
                                                                    <p className='font-size-12 color-black mb-1'>{moment(evv?.event_date).format(
                                                                        "dddd, D MMMM YYYY"
                                                                    )}
                                                                    </p>
                                                                </div>
                                                                <div className='wf-couple-update text-md-center' style={{ width: "" }}>
                                                                    <h3 className="font-size-16 fw-medium color-black mb-1">{evv?.event_city?.name}</h3>
                                                                    <p className="font-size-12 pink-color mb-1"><u>Location</u></p>
                                                                </div>
                                                                <div className='d-flex flex-wrap justify-content-between align-items-center gap-3 text-center' style={{ maxWidth: "400px", width: "100%" }}>
                                                                    <div className='wf-couple-update'>
                                                                        <h3 className="font-size-16 fw-medium color-black mb-1">{evv?.eventTasks?.length}</h3>
                                                                        <p className="font-size-12 pink-color mb-1"><u>Tasks</u></p>
                                                                    </div>
                                                                    <div className='wf-couple-update'>
                                                                        <h3 className="font-size-16 fw-medium color-black mb-1">AED {evv?.eventBudget?.event_total || 0}</h3>
                                                                        <p className="font-size-12 pink-color mb-1"><u>Budget</u></p>
                                                                    </div>
                                                                    <div className='wf-couple-update'>
                                                                        <h3 className="font-size-16 fw-medium color-black mb-1"> {" "}
                                                                            {evv?.eventGuests?.length &&
                                                                                evv?.eventGuests?.reduce(
                                                                                    (total, guest) =>
                                                                                        total +
                                                                                        (guest.male_count || 0) +
                                                                                        (guest.female_count || 0) +
                                                                                        (guest.child_count || 0),
                                                                                    0
                                                                                )}</h3>
                                                                        <p className="font-size-12 pink-color mb-1"><u>Guests</u></p>
                                                                    </div>
                                                                    <div className='wf-couple-update'>
                                                                        <h3 className="font-size-16 fw-medium color-black mb-1">{evv?.eventFavourites?.length}</h3>
                                                                        <p className="font-size-12 pink-color mb-1"><u>Shortlist</u></p>
                                                                    </div>
                                                                    <div className='wf-couple-update'>
                                                                        <h3 className="font-size-16 fw-medium color-black mb-1">{evv?.eventBookings?.length || 0}</h3>
                                                                        <p className="font-size-12 pink-color mb-1"><u>Bookings</u></p>
                                                                    </div>
                                                                </div>

                                                                {/* <div className='col-xl-1 col-md-3 text-end'>
                                                    <div className='color-grey cursor-pointer'>
                                                        <i class="bi bi-chevron-right ms-2"></i>
                                                    </div>
                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>))}
                                                </div>)}</>)) : <NoEventUI name={"Couples"} />}

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>

            <Modal
                closeTimeoutMS={500}
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Add Couple"
                className="modal fade show wf-modal"
            >
                <AddCouple closeModal={closeModal} refreshEventList={refreshEventList} />
            </Modal>


        </>
    )
}

export default CoupleList