import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import NoEventUI from './noEventUI';
import $ from "jquery";
import {
  getProfile, getEventList, getEventBudget, editEventBudget, addBudgetCategory, editBudgetCategory, deleteBudgetCategory, addManualBookings, editBooking
} from "../../store/slices/userSlice";
import axios from "axios";
import CaregoriesButtonSkeleton from "../LoadingSkeleton/CaregoriesButtonSkeleton";
import ButtonSkeleton from "../LoadingSkeleton/ButtonSkeleton";
import Modal from "react-modal";
import ListSkeleton from "../LoadingSkeleton/ListSkeleton";
import { useTranslation } from "react-i18next";
import { ArrowUpDown, Banknote, ListFilter, ScrollText, Search } from "lucide-react";
import { PieChart } from 'react-minimal-pie-chart';
import { Clock12 } from "lucide-react";

const customStyles = {
  content: {
    display: "block",
  },
};
Modal.setAppElement("#root");


const Budget = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [actualmyEvents, setactualmyEvents] = useState([]);
  const myEvents = useSelector(getEventList);
  const profile = useSelector(getProfile);
  const [toggle, settoggle] = useState([]);
  const [filtertoggle, setfiltertoggle] = useState(true); //true to open
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState([]);
  const [load, setload] = useState(false);
  const [modalType, setmodalType] = useState("");
  const [modalType1, setmodalType1] = useState("");
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [modalIsOpen1, setmodalIsOpen1] = useState(false);
  const [eventBudget, seteventBudget] = useState([]);
  const [totalBudget, settotalBudget] = useState([]);
  const [budgetclicked, setbudgetclicked] = useState([]);
  const [actualinitialdeposit, setactualinitialdeposit] = useState("");
  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);
  const [i, seti] = useState(false);
  const [j, setj] = useState(false);
  const [load1, setload1] = useState(false);
  const [search, setsearch] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const [coupleURLFilter, setcoupleURLFilter] = useState(queryParams.get("couple") ? JSON.parse(queryParams.get("couple")) : []);
  const [selectedCouple, setselectedCouple] = useState("");
  const [coupleEventURLFilter, setcoupleEventURLFilter] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);
  const isDashboard = location.pathname !== "/bookings"
  const [updated, setupdated] = useState(true);






  //booking states
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [totalprice, settotalprice] = useState(0);
  const [paymentstatus, setpaymentstatus] = useState("fullypaid");
  const [initdeposit, setinitdeposit] = useState(0);
  const [initdeposittask, setinitdeposittask] = useState(false);
  const [initdepositdate, setinitdepositdate] = useState("");
  const [finaldeposit, setfinaldeposit] = useState(0);
  const [finaldeposittask, setfinaldeposittask] = useState(false);
  const [finaldepositdate, setfinaldepositdate] = useState("");
  const [remainingAmount, setremainingAmount] = useState(0);
  const [validationmessage, setvalidationmessage] = useState("");
  const [selectedcategories, setSelectedCategories] = useState([]);

  const [eventlistdata, seteventlistdata] = useState([]);
  const [categorylistdata, setcategorylistdata] = useState([]);
  const [graphdata, setgraphdata] = useState([]);


  //Filters
  const [event, setevent] = useState(queryParams.get("events") ? JSON.parse(queryParams.get("events")) : []);
  const [category_id, setcategory_id] = useState([]);
  const [filteredData, setfilteredData] = useState([]);


  const EventList = myEvents?.map((ev) => {
    return {
      couple_id: ev?._id,
      id: ev?.Event?._id,
      name: ev?.Event?.event_name,
      color: ev?.Event?.event_color,
      date: ev?.Event?.event_date,
    };
  });



  function getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }




  const handleToggleChange = (id) => {
    console.log('idddddddddddddddd', id);
    if (!toggle.includes(id)) {
      settoggle([...toggle, id]);
    } else {
      const updatedEventIds = toggle.filter((itemId) => itemId !== id);
      settoggle(updatedEventIds);
    }
  };


  const handleSkillChange = (eventid) => {
    console.log('eventid', eventid)
    if (!selectedcategories.includes(eventid)) {
      setSelectedCategories([...selectedcategories, eventid]);
    } else {
      const updatedEventIds = selectedcategories.filter((id) => id !== eventid);
      setSelectedCategories(updatedEventIds);
    }
  };


  const viewModal = async (type, clickedbudget, i, j) => {
    console.log('dataassss', type, clickedbudget, i, j)
    settotalBudget(clickedbudget.event_total)
    seti(i)
    setj(j)
    setbudgetclicked(clickedbudget)
    setactualinitialdeposit(clickedbudget ? clickedbudget?.budget_category[i]?.category_items[j]?.initialDeposit : "")
    setmodalIsOpen(true);
    setmodalType(type);
  };

  const viewModal1 = async (type) => {

    setmodalIsOpen1(true);
    setmodalType1(type);
  };

  function closeModal() {
    setmodalIsOpen(false);

    settitle("")
    setdescription("");
    settotalprice("");
    setpaymentstatus("");
    setinitdeposit("");
    setinitdeposittask(false);
    setinitdepositdate("");
    setfinaldeposit("");
    setfinaldeposittask(false);
    setfinaldepositdate("");
    setremainingAmount(0);
    setvalidationmessage("");
    setSelectedCategories([]);
  }

  function closeModal1() {
    setmodalIsOpen1(false);
    setmodalType1("");
  }


  const handleEventChange = (id) => {
    console.log('idddddddddddddddd', id);
    if (!event.includes(id)) {
      setevent([...event, id]);
    } else {
      const updatedEventIds = event.filter((itemId) => itemId !== id);
      setevent(updatedEventIds);
    }
  };

  const handleCategoryChange = (id) => {
    if (!category_id.includes(id)) {
      setcategory_id([...category_id, id]);
    } else {
      const updatedcategoryIds = category_id.filter((itemId) => itemId !== id);
      setcategory_id(updatedcategoryIds);
    }
  };

  console.log('graphdata', graphdata)

  const handleSetData = (e) => {

    const mergedEvents = [];


    e?.forEach(event => {
      const modifiedEvent = {
        ...event,
        Event: [event.Event]
      };

      const existingEventIndex = mergedEvents?.findIndex(
        mergedEvent => mergedEvent?.person1?._id === event?.person1?._id && mergedEvent?.person2?._id === event?.person2?._id
      );

      if (existingEventIndex !== -1) {
        mergedEvents[existingEventIndex].Event.push(event.Event);
      } else {
        mergedEvents.push(modifiedEvent);
      }
    });

    setactualmyEvents(mergedEvents)
    // setfilteredData(mergedEvents)
  }


  useEffect(() => {
    handleSetData(myEvents)
  }, [myEvents]);




  const getAllEventBudget = async () => {
    try {


      seteventBudget(null);
      setfilteredData(null);
      console.log('myEvents', myEvents)

      const eventIdss = []

      for (const events of myEvents) {
        if (events.Event && events.Event._id) {
          eventIdss.push(events.Event._id);
        }
      }

      console.log('eventIdss', eventIdss)

      const response = await dispatch(
        getEventBudget({ event_ids: eventIdss })
      ).unwrap();

      if (response) {
        seteventBudget(response?.data);
        setfilteredData(response?.data);

        const graphValues = calculateGraphValues(myEvents, response.data)
        setgraphdata(graphValues)

        // const eventlist= sumSimilarObjects(response.data)
        // seteventlistdata(eventlist)

        const categorylist = sumSimilarObjectsCategory(response.data)
        setcategorylistdata(categorylist)

      }
      setload(false);

    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };


  function calculateGraphValues(allEvents, data) {

    const finalData = allEvents?.map(eventItem => {
      let matchingEvent = []
      if (eventItem?.Event && eventItem?.Event?.event_name) {
        matchingEvent = data?.filter(item => item?.event_id?.event_name == eventItem?.Event?.event_name);
        console.log('matchingEvent', matchingEvent)

        if (matchingEvent) {
          // const { male_count, female_count, child_count } = matchingEvent;
          // const totalValue = male_count + female_count + child_count;
          return {
            title: eventItem.Event.event_name,
            value: matchingEvent?.reduce((sum, obj) => {
              const countsSum = obj.event_total;
              return (sum + countsSum);
            }, 0),
            color: eventItem.Event.event_color
          };
        }
        else {
          return {
            title: eventItem.Event.event_name,
            value: 0,
            color: eventItem.Event.event_color
          };
        }
      }
    })
    const filteredFinalData = finalData?.filter(item => item !== null && item !== undefined);
    return filteredFinalData;
  }

  // console.log('eventlistdata', eventlistdata)
  //   function sumSimilarObjects(arr) {
  //     console.log('arr', arr)

  //     const sums = {};


  //     arr?.forEach(event => {
  //         event?.budget_category?.forEach(category => {
  //             category?.category_items?.forEach(obj => {
  //                 const event_id = obj.user_event._id; 


  //                 if (event_id in sums) {
  //                     sums[event_id].paid += obj.paid || 0;
  //                     sums[event_id].due += obj.due || 0;
  //                     sums[event_id].totalPrice += obj.totalPrice || 0;
  //                 } else {

  //                     sums[event_id] = {
  //                         paid: obj.paid || 0,
  //                         due: obj.due || 0,
  //                         totalPrice: event.event_total || 0,

  //                     };
  //                 }
  //             });
  //         });
  //     });

  //     // Convert the sums object back to an array of objects
  //     const result = Object.keys(sums).map(eventId => {
  //         const eventDetails = arr?.find(event =>
  //             event.budget_category.some(category =>
  //                 category.category_items.some(item => item.user_event._id === eventId)
  //             )
  //         );



  //         return {
  //             _id: eventId,
  //             event_name: eventDetails?.event_id?.event_name, 
  //             event_color: eventDetails?.event_id?.event_color, 
  //             paid: sums[eventId]?.paid,
  //             due: sums[eventId]?.due,
  //             totalPrice: sums[eventId]?.totalPrice
  //         };
  //     });

  //     arr.forEach(obj => {
  //       // Check if obj.event_id._id is not present in result
  //       if (!result.some(event => event._id === obj.event_id._id)) {
  //           // Create a new event object
  //           let newEvent = {
  //               _id: obj.event_id._id,
  //               event_name: obj.event_id.event_name,
  //               event_color: obj.event_id.event_color,
  //               due: 0,
  //               paid: 0,
  //               totalPrice: obj.event_total
  //           };
  //           // Push the new event object to result
  //           result.push(newEvent);
  //       }
  //   });

  //     return result.filter(obj => obj._id !== "undefined");
  // }


  function sumSimilarObjectsCategory(arr) {

    const sums = {};


    arr.forEach(obj => {
      obj.budget_category.forEach(category => {
        const category_id = category.category_id._id;


        if (!(category_id in sums)) {
          sums[category_id] = {
            _id: category_id,
            name: category.category_id.name,
            imageName: category.category_id.imageName,
            slug: category.category_id.slug,
            totalPrice: 0,
            paid: 0,
            due: 0,
            length: 0
          };
        }


        category.category_items.forEach(item => {
          sums[category_id].totalPrice += item.totalPrice || 0;
          sums[category_id].paid += item.paid || 0;
          sums[category_id].due += item.due || 0;
        });


        sums[category_id].length += 1;
      });
    });


    const result = Object.keys(sums).map(category_id => ({
      _id: category_id,
      name: sums[category_id].name,
      imageName: sums[category_id].imageName,
      slug: sums[category_id].slug,
      paid: sums[category_id].paid,
      totalPrice: sums[category_id].totalPrice,
      due: sums[category_id].due,
      length: sums[category_id].length
    }));

    return result;
  }








  console.log("categorylistdata", categorylistdata)

  const getCategories = async () => {
    try {
      setload(true);
      const response = await axios.get(`user/getservices`);
      console.log("responsexx", response);
      setAllCategories(response?.data?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) { }
  };

  useEffect(() => {
    let mount = true;
    if (mount) {

      getAllEventBudget();
    }
    return () => {
      mount = false;
    };
  }, [myEvents]);


  useEffect(() => {

    getCategories()

  }, [myEvents]);


  const accountDelete = async () => {
    try {
      setload1(true)
      // console.log('iasassa', i)
      // return
      await dispatch(
        deleteBudgetCategory({
          budgetCategory_id: i,
          budget_id: budgetclicked._id
        })
      ).unwrap();

      const updatedEventIds = selectedcategories.filter((id) => id !== id);
      setSelectedCategories(updatedEventIds);
      closeModal();
      $("#tableData").DataTable().destroy();
      try {
        await getAllEventBudget()
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
      setload1(false)
    } catch (rejectedValueOrSerializedError) {
      setload1(false)
      console.log(rejectedValueOrSerializedError);
    }
  };


  const handleRemove = () => {




    if (
      i >= 0 &&
      i < budgetclicked.budget_category.length &&
      j >= 0 &&
      j < budgetclicked.budget_category[i].category_items.length
    ) {
      setload1(true)
      const newData = { ...budgetclicked };

      newData.budget_category[i].category_items.splice(
        j,
        1
      );
      setbudgetclicked(newData);



      handleSave()
      setload1(false)
    } else {
      console.error("Invalid category index or item index");
      setload1(false)
    }
  };


  const handleSave = async () => {
    setload1(true)
    let totalallocated = ""
    let ch = budgetclicked?.budget_category?.reduce((sum, obj) => sum + obj.allocated, 0)



    totalallocated = budgetclicked?.budget_category.reduce(
      (acc, category) => {
        const allocatedValue =
          parseInt(category.allocated) || 0;
        return acc + allocatedValue;
      },
      0
    );


    let paid = budgetclicked?.budget_category[i]?.category_items?.reduce((acc, category) => {
      const allocatedValue = parseInt(category.paid) || 0;
      return acc + allocatedValue;
    }, 0);

    let due = budgetclicked?.budget_category[i]?.category_items?.reduce((acc, category) => {
      const allocatedValue = parseInt(category.due) || 0;
      return acc + allocatedValue;
    }, 0);





    let a = budgetclicked?.budget_category[i]?.allocated
    let b = paid
    let c = due
    let asd = a - (b + c)

    console.log('aSD', `${a}-(${b}+${c})=${asd}`)
    if (asd < 0) {
      setload1(false)
      setcheck2(true)
      toast.error("Paid value most not be greater than Allocated budget")
    }

    else if (totalallocated > budgetclicked?.event_total) {
      setload1(false)
      toast.error(
        "Allocated budget most not be greater than total budget"
      );
      setcheck1(true)
    }


    else if (totalBudget < ch) {
      setload1(false)
      toast.error("Budget cannot be less than the allocated values")
    }


    else {


      const abc = await dispatch(
        editEventBudget({
          budget_id: budgetclicked?._id,
          budgetCategory: JSON.stringify(budgetclicked?.budget_category),
          budget: totalBudget,
        })
      ).unwrap();

      console.log("abcccc", abc);

      await getAllEventBudget()
      closeModal()
      setload1(false)

    }
  };
  console.log('budgetclicked', budgetclicked)



  const EditCategoryAllocation = async (route) => {

    setload1(true)

    const abc = await dispatch(
      editBudgetCategory({
        allocated: budgetclicked?.budget_category[i]?.allocated,
        budget_id: budgetclicked?._id,
        budgetCategory_id: budgetclicked?.budget_category[i]?._id,
        force: route == "modal" ? true : false,
      })
    ).unwrap();

    if (abc.data.remainingAmount > 0 && abc.status == 0) {
      setvalidationmessage(abc.message);
      setremainingAmount(abc.data.remainingAmount)
      viewModal1("add1")
    }


    else {
      await getAllEventBudget()
      closeModal1()
      closeModal()
    }

    setload1(false)
  }

  const saveBranch = async (route) => {
    try {
      setload1(true)
      console.log('initdeposit', initdeposit, totalprice)

      if (!paymentstatus) {
        toast.error("Payment status is required")
      }
      else if (!title) {
        toast.error("Title is required")
      }
      else if (parseInt(initdeposit) > parseInt(totalprice)) {
        toast.error("Initial deposit cannot be greater than total price")
      }
      else if (parseInt(finaldeposit) > parseInt(totalprice)) {
        toast.error("Final deposit cannot be greater than total price")
      }
      else if (parseInt(finaldeposit) + parseInt(initdeposit) > parseInt(totalprice)) {
        toast.error("Price cannot be greater then total price")
      }
      else {

        const response = await dispatch(
          addManualBookings({
            event_id: budgetclicked.event_id._id,
            store_category_id: budgetclicked.budget_category[i].category_id._id,
            title: title,
            description: description,
            price: totalprice,
            paymentStatus: paymentstatus,
            initialDeposit: initdeposit,
            initDepositDate: initdepositdate,
            // initDepositTask: initdeposittask,
            finalDeposit: totalprice - initdeposit,
            finalDepositDate: finaldepositdate,
            // finalDepositTask: finaldeposittask,
            price: totalprice,
            remainingAmount: remainingAmount > 0 ? remainingAmount : null,
            force: route == "modal" ? true : false,
          })
        ).unwrap();

        console.log("responseeeee34", response);
        // return
        if (response.data.remainingAmount > 0 && response.status == 0) {
          setvalidationmessage(response.message);
          setremainingAmount(response.data.remainingAmount);
          viewModal1("add");

        } else {
          settitle("");
          setdescription("");
          settotalprice(0);
          setinitdeposit(0);
          setinitdeposittask(false);
          setinitdepositdate("");
          setfinaldeposit(0);
          setfinaldeposittask(false);
          setfinaldepositdate("");
          setvalidationmessage("");
          setremainingAmount(0);

          getAllEventBudget();
          setmodalIsOpen(false);
          setmodalIsOpen1(false);
          setmodalType("")
          setmodalType1("")
        }

      }
      setload1(false)
    } catch (rejectedValueOrSerializedError) {
      setload1(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const editBooking = async (route) => {
    try {
      setload1(true)
      console.log('budgetclickeddd', budgetclicked?.budget_category[i]?.category_items[j])


      if (!budgetclicked?.budget_category[i]?.category_items[j]?.paymentStatus) {
        toast.error("Payment status is required")
      }
      else if (!budgetclicked?.budget_category[i]?.category_items[j]?.title) {
        toast.error("Title is required")
      }
      else if (parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.initDeposit) > parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.totalPrice)) {
        toast.error("Initial deposit cannot be greater than total price")
      }
      else if (parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.finalDeposit) > parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.totalPrice)) {
        toast.error("Final deposit cannot be greater than total price")
      }
      else if (parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.finalDeposit) + parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.initialDeposit) > parseInt(budgetclicked?.budget_category[i]?.category_items[j]?.totalPrice)) {
        toast.error("Price cannot be greater then total price")
      }
      else {

        const response = await dispatch(
          editBooking({
            data: budgetclicked?.budget_category[i]?.category_items[j]
          })
        ).unwrap();

        console.log("responseeeee34", response);
        // return
        if (response.data.remainingAmount > 0 && response.status == 0) {
          setvalidationmessage(response.message);
          setremainingAmount(response.data.remainingAmount);
          viewModal1("add");

        } else {
          settitle("");
          setdescription("");
          settotalprice(0);
          setinitdeposit(0);
          setinitdeposittask(false);
          setinitdepositdate("");
          setfinaldeposit(0);
          setfinaldeposittask(false);
          setfinaldepositdate("");
          setvalidationmessage("");
          setremainingAmount(0);

          getAllEventBudget();
          setmodalIsOpen(false);
          setmodalIsOpen1(false);
          setmodalType("")
          setmodalType1("")
        }

      }
      setload1(false)
    } catch (rejectedValueOrSerializedError) {
      setload1(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  const handleSaveCategory = async () => {
    setload1(true)
    if (selectedcategories?.length > 0) {
      await dispatch(
        addBudgetCategory({
          budget_id: budgetclicked?._id,
          event_id: budgetclicked?.event_id?._id,
          categoryIds: JSON.stringify(selectedcategories)
        })
      ).unwrap();
      await getAllEventBudget();
      closeModal()
      setload1(false)
    }
    setload1(false)
    closeModal()
  };


  const updateCategoryItem = (index, key, value) => {
    const updatedBudgetClicked = { ...budgetclicked };

    if (key == "totalPrice") {
      const enteredValue = parseFloat(value);
      if (value == "") {
        updatedBudgetClicked.budget_category[i].category_items[index]["totalPrice"] = 0;
        updatedBudgetClicked.budget_category[i].category_items[index]["finalDeposit"] = 0;
        updatedBudgetClicked.budget_category[i].category_items[index]["initialDeposit"] = 0;
        setbudgetclicked(updatedBudgetClicked);
      }

      else if (!isNaN(enteredValue) && enteredValue >= 0) {
        updatedBudgetClicked.budget_category[i].category_items[index]["totalPrice"] = enteredValue;
        setbudgetclicked(updatedBudgetClicked);
      }
      else {
        value = null
      }
    }

    if (key == "initialDeposit") {
      const enteredValue = parseFloat(value);
      if (!isNaN(enteredValue) && enteredValue >= 0) {
        updatedBudgetClicked.budget_category[i].category_items[index]["initialDeposit"] = enteredValue;
        setbudgetclicked(updatedBudgetClicked);
      }
      else {
        value = null
      }
    }

    if (key == "paymentStatus") {
      if (value == "partiallypaid") {
        updatedBudgetClicked.budget_category[i].category_items[index]["initialDeposit"] = actualinitialdeposit;
        setbudgetclicked(updatedBudgetClicked);
      }
      else {
        updatedBudgetClicked.budget_category[i].category_items[index]["initialDeposit"] = 0;
        setbudgetclicked(updatedBudgetClicked);
      }
    }

    updatedBudgetClicked.budget_category[i].category_items[index][key] = value;
    setbudgetclicked(updatedBudgetClicked);
  };



  console.log('filteredData', filteredData)

  useEffect(() => {
    if (search === "") {
      handleFilters();
    } else {
      const searchText = search.toLowerCase();

      const filteredEvents = eventBudget.filter(event => {
        return event.budget_category.some(category =>
          category.category_items.some(item => {
            const titleMatch = item?.title?.toLowerCase().includes(searchText);
            const storeNameMatch = item?.vendor_event?.store_name?.toLowerCase().includes(searchText);
            const eventNameMatches = event?.event_id?.event_name?.toLowerCase().includes(searchText);
            const coupleMatches = coupleURLFilter.length == "" || coupleURLFilter.includes(i?.event_id?.user_id);
            const eventmatches = event == "" || item.user_event._id == i?.event_id?._id
            const categorymatches = category_id == "" || category_id.every((categoryId) =>
              i?.budget_category?.some(
                (category) => category?.category_id?._id === categoryId
              )
            );
            const coupleFilterMatches = selectedCouple == "" || selectedCouple == i?.event_id?.user_id;
            return titleMatch || storeNameMatch || eventNameMatches && eventmatches && categorymatches && coupleMatches && coupleFilterMatches;
          })
        );
      });

      setfilteredData(filteredEvents);
    }
  }, [search]);



  const handleFilters = () => {
    console.log('yesss')

    const filteredData = eventBudget?.filter((i) => {
      const coupleMatches = coupleURLFilter.length == "" || coupleURLFilter.includes(i?.event_id?.user_id);
      const eventmatches = event == "" || event.includes(i?.event_id._id)
      const categorymatches = category_id == "" || category_id.every((categoryId) =>
        i?.budget_category?.some(
          (category) => category?.category_id?._id === categoryId
        )
      );
      const coupleFilterMatches = selectedCouple == "" || selectedCouple == i?.event_id?.user_id;


      console.log('coupleMatches', coupleMatches)
      return eventmatches && categorymatches && coupleMatches && coupleFilterMatches

    })



    setfilteredData(filteredData)

  };


  useEffect(() => {

    const newCoupleURLFilter = queryParams.get("couple") || "";

    console.log('newCoupleURLFilter', newCoupleURLFilter)

    setcoupleURLFilter(newCoupleURLFilter);

    const newcoupleEventURLFilter = queryParams.get("events") || [];

    setevent(newcoupleEventURLFilter)

    setfiltertoggle(!filtertoggle)
  }, [location.search]);



  useEffect(() => {

    handleFilters();


  }, [filtertoggle, eventBudget]);



  console.log('validationmessage', validationmessage)

  console.log('eventBudget', eventBudget)

  return (
    <>

      {modalType1 == "add" ? (

        <Modal
          closeTimeoutMS={500}
          isOpen={modalIsOpen1}
          onRequestClose={closeModal1}
          style={customStyles}
          contentLabel="Add Task"
          className={`modal fade show wf-modal wf-delete-modal`}
        >
          <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
            <div className="modal-content" id="addbooking">
              <>
                <div class="modal-header border-0 pb-0">
                  <h1 class="font-size-22 dm-font purple-color mb-0" id="staticBackdropLabel">{t('Confirmation')}</h1>
                  <button type="button" onClick={() => closeModal1()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body mt-0">
                  <p className="font-size-14 color-black mb-0">{validationmessage}</p>
                </div>
                <div class="modal-footer border-0 justify-content-center gap-2">
                  <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={() => closeModal1()}>{t('Cancel')}</button>
                  <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => saveBranch("modal")} disabled={load1}>
                    {load1 ? (
                      <div className="spinner-border wf-spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <>{t('Accept')}</>
                    )}</button>
                </div>

              </>
            </div>
          </div>
        </Modal>

      ) :
        modalType1 == "add1" ? (

          <Modal
            closeTimeoutMS={500}
            isOpen={modalIsOpen1}
            onRequestClose={closeModal1}
            style={customStyles}
            contentLabel="Add Task"
            className={`modal fade show wf-modal wf-delete-modal`}
          >
            <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
              <div className="modal-content" id="addbooking">
                <>
                  <div class="modal-header border-0 pb-0">
                    <h1 class="font-size-22 dm-font purple-color mb-0" id="staticBackdropLabel">{t('Confirmation')}</h1>
                    <button type="button" onClick={() => closeModal1()} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body mt-0">
                    <p className="font-size-14 color-black mb-0">{validationmessage}</p>
                  </div>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={() => closeModal1()}>{t('Cancel')}</button>
                    <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => EditCategoryAllocation("modal")} disabled={load1}>
                      {load1 ? (
                        <div className="spinner-border wf-spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>{t('Accept')}</>
                      )}</button>
                  </div>

                </>
              </div>
            </div>
          </Modal>

        ) : (
          <Modal
            closeTimeoutMS={500}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Add Task"
            className={`modal fade show wf-modal ${modalType === 'budgetedit' || modalType === 'delete-category' ? 'wf-delete-modal' : ''} ${modalType === 'allocationedit' ? 'wf-allocation-modal' : ''}`}
          >
            <div className="modal-dialog modal-dialog-centered wf-modal-dialog">
              {modalType == "addbooking" || modalType == "editcategoryitempayments" ? (<>
                <div className="modal-content" id="addbooking">
                  <div class="modal-header border-0 pb-0">
                    <h1 class="font-size-22 dm-font purple-color" id="addbooking">
                      {t('Add')} {t('Booking')}
                    </h1>
                    <button
                      type="button"
                      onClick={() => closeModal("create")}
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">

                    <div className="row mb-3 gy-3">
                      <div className="col-md-6">
                        <p className="font-size-18 dm-font pink-color mb-2">{t('Event')}</p>
                        <div className="event-list wf-shadow-2 rounded py-3 px-3 white-bg" style={{ borderLeft: `8px solid ${budgetclicked.event_id.event_color}` }}>
                          <p className="font-size-12 color-black mb-0 text-capitalize">{budgetclicked.event_id.event_name}</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="font-size-18 dm-font pink-color mb-2">{t('Category')}</p>
                        <div className="d-flex align-items-center gap-2 wf-shadow-2 rounded py-2 px-3 white-bg">
                          <img width="30" src={`${process.env.REACT_APP_IMGURL}${budgetclicked.budget_category[i].category_id.imageName}`} alt="" className="mb-1" />
                          <p className="font-size-12 color-black mb-0 text-capitalize">{budgetclicked.budget_category[i].category_id.name}</p>
                        </div>
                      </div>
                    </div>

                    <div class="form-floating wf-input wf-input-101 mb-3">
                      <input
                        onChange={(e) => modalType == "editcategoryitempayments" ? updateCategoryItem(j, "name", e.target.value) : settitle(e.target.value)}
                        type="text"
                        defaultValue={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.title : ""}
                        class="form-control"
                        id="floatingInput"
                        placeholder="Booking Title"
                        required
                      />
                      <label for="floatingInput">{t('Booking')} {t('Title')}</label>
                    </div>

                    <div class="form-floating wf-textarea wf-textarea-101 mb-3">
                      <textarea
                        onChange={(e) => modalType == "editcategoryitempayments" ? updateCategoryItem(j, "description", e.target.value) : setdescription(e.target.value)}
                        defaultValue={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.description : description}
                        class="form-control"
                        placeholder="Booking Description"
                        id="booking-description"
                        style={{ height: "100px" }}
                      ></textarea>
                      <label for="booking-description">{t('Booking')} {t('Description')}</label>
                    </div>

                    <div className="row mb-3 gy-3">
                      <div className="col-md-6">
                        <p className="font-size-18 dm-font pink-color mb-2">
                          {t('TotalPrice')}
                        </p>
                        <div class="form-floating wf-input wf-input-101">
                          <input
                            onChange={(e) => {
                              if (modalType == "editcategoryitempayments") {
                                updateCategoryItem(j, "totalPrice", e.target.value)
                              }
                              else {
                                const enteredValue = parseFloat(e.target.value);
                                if (e.target.value == "") {
                                  setinitdeposit(0)
                                  setfinaldeposit(0)
                                  settotalprice(0)
                                }

                                else if (!isNaN(enteredValue) && enteredValue >= 0) {
                                  settotalprice(enteredValue);
                                }
                                else {
                                  e.target.value = null
                                }

                              }
                            }}
                            type="number"
                            defaultValue={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.totalPrice : ""}
                            class="form-control"
                            id="price-total"
                            placeholder="Total Price"
                            autocomplete="price"
                            required
                          />
                          <label for="price-total">{t('TotalPrice')}</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <p className="font-size-18 dm-font pink-color mb-2">
                          {t('PaymentStatus')}
                        </p>
                        <div class="d-flex gap-2 flex-wrap">
                          <input
                            checked={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.paymentStatus == "fullypaid" : paymentstatus == "fullypaid"}
                            onChange={(e) => {
                              modalType == "editcategoryitempayments" ? updateCategoryItem(j, "paymentStatus", e.target.value) : setpaymentstatus(e.target.value);
                              setinitdeposit(0)
                            }}
                            value="fullypaid"
                            type="radio"
                            name="payment-status"
                            class="btn-check"
                            id="Fully-Paid"
                          />
                          <label
                            style={{
                              lineHeight: "23px",
                              "--bs-btn-active-bg": "var(--color3)",
                            }}
                            class="btn wf-select-btn py-3 px-4"
                            for="Fully-Paid"
                          >
                            {t('FullyPaid')}
                          </label>

                          <input
                            checked={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.paymentStatus == "partiallypaid" : paymentstatus == "partiallypaid"}
                            onChange={(e) => {
                              modalType == "editcategoryitempayments" ? updateCategoryItem(j, "paymentStatus", e.target.value) : setpaymentstatus(e.target.value);
                              setinitdeposit(0)
                            }}
                            value="partiallypaid"
                            type="radio"
                            name="payment-status"
                            class="btn-check"
                            id="Partially-Paid"
                          />
                          <label
                            style={{
                              lineHeight: "23px",
                              "--bs-btn-active-bg": "var(--color3)",
                            }}
                            class="btn wf-select-btn py-3 px-4"
                            for="Partially-Paid"
                          >
                            {t('PartiallyPaid')}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-3 gy-3" id="Initial-Deposit">
                      <div className="col-md-5">
                        <div class="form-floating wf-input wf-input-101">
                          <input
                            disabled={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.paymentStatus == "fullypaid" : paymentstatus == "fullypaid"}
                            onChange={(e) => {
                              if (modalType == "editcategoryitempayments") {
                                updateCategoryItem(j, "initialDeposit", e.target.value)
                              }
                              else {

                                const enteredValue = parseFloat(e.target.value); // Use parseFloat for decimal values
                                if (!isNaN(enteredValue) && enteredValue >= 0) {
                                  setinitdeposit(enteredValue);
                                }
                                else {
                                  e.target.value = null
                                }
                              }
                            }}
                            value={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.initialDeposit : initdeposit}
                            type="number"
                            class="form-control"
                            id="Initial-Deposit"
                            placeholder="Initial Deposit"
                            autocomplete="price"
                            required
                          />
                          <label for="Initial-Deposit">{t('InitialDeposit')}</label>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div class="form-floating wf-input wf-input-101">
                          <input
                            disabled={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.paymentStatus == "fullypaid" : paymentstatus == "fullypaid"}
                            onChange={(e) => modalType == "editcategoryitempayments" ? updateCategoryItem(j, "initialDepositDate", e.target.value) : setinitdepositdate(e.target.value)}
                            type="date"
                            min={getCurrentDate()}
                            class="form-control"
                            id="Due-Date"
                            placeholder="Due Date"
                            autocomplete="price"
                            required
                          />
                          <label for="Due-Date">{t('DueDate')}</label>
                        </div>
                      </div>
                      {modalType == "addbooking" && (<div className="col-md-2 align-self-center">
                        <div class="form-check wf-signup-checkbox ">
                          <input
                            disabled={paymentstatus == "fullypaid"}
                            checked={initdeposittask}
                            onChange={(e) => setinitdeposittask(!initdeposittask)}
                            class="form-check-input wf-shadow-2"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label pink-color"
                            for="flexCheckDefault"
                          >
                            <u>{t('Add')} {t('Task')}</u>
                          </label>
                        </div>
                      </div>)}
                    </div>

                    <div className="row mb-3 gy-3" id="Final-Payment">
                      <div className="col-md-5">
                        <div class="form-floating wf-input wf-input-101">
                          <input
                            disabled
                            value={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.paymentStatus == "fullypaid" ? budgetclicked?.budget_category[i]?.category_items[j]?.totalPrice : budgetclicked?.budget_category[i]?.category_items[j]?.totalPrice - budgetclicked?.budget_category[i]?.category_items[j]?.initialDeposit : paymentstatus == "fullypaid" ? totalprice : totalprice - initdeposit}
                            type="number"
                            class="form-control"
                            id="Final-Payment"
                            placeholder="Final Deposit"
                            autocomplete="price"
                            required
                          />
                          <label for="Final-Payment">{t('FinalPayment')}</label>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div class="form-floating wf-input wf-input-101">
                          <input
                            defaultValue={modalType == "editcategoryitempayments" ? budgetclicked?.budget_category[i]?.category_items[j]?.finalDepositDate : finaldepositdate}
                            onChange={(e) => modalType == "editcategoryitempayments" ? updateCategoryItem(j, "finalDepositDate", e.target.value) : setfinaldepositdate(e.target.value)}
                            type="date"
                            min={getCurrentDate()}
                            class="form-control"
                            id="Due-Date"
                            placeholder="Due Date"
                            autocomplete="price"
                            required
                          />
                          <label for="Due-Date">{t('DueDate')}</label>
                        </div>
                      </div>
                      {modalType == "addbooking" && (<div className="col-md-2 align-self-center">
                        <div class="form-check wf-signup-checkbox ">
                          <input
                            checked={finaldeposittask}
                            onChange={(e) => setfinaldeposittask(!finaldeposittask)}
                            class="form-check-input wf-shadow-2"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            class="form-check-label pink-color"
                            for="flexCheckDefault"
                          >
                            <u>{t('Add')} {t('Task')}</u>
                          </label>
                        </div>
                      </div>)}
                    </div>
                  </div>
                  <div class="modal-footer border-0 justify-content-center gap-2">
                    <button
                      class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2"
                      onClick={() => closeModal("create")}
                    >
                      {t('Cancel')}
                    </button>
                    <button
                      onClick={() => modalType == "editcategoryitempayments" ? editBooking() : saveBranch("")}
                      class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2"
                      disabled={load1}
                    >
                      {load1 ? (
                        <div className="spinner-border wf-spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <>{t('Save')}</>
                      )}
                    </button>
                  </div>
                </div>
              </>) :
                modalType == "budgetedit" ? (<>
                  <div className="modal-content" id="addbooking">
                    <div class="modal-header border-0 pb-0">
                      <h1 class="font-size-22 dm-font purple-color" id="addbooking">{t('Budget')} {t('Edit')}</h1>
                      <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div className="row mb-3 gy-3">
                        <div className="col-md-6">
                          <p className="font-size-18 dm-font pink-color mb-2">{t('Event')}</p>
                          <div className="event-list wf-shadow-2 rounded py-3 px-3 white-bg" style={{ borderLeft: `8px solid ${budgetclicked.event_id.event_color}` }}>
                            <p style={{ lineHeight: '26px' }} className="font-size-12 color-black mb-0 text-capitalize">{budgetclicked.event_id.event_name}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="font-size-18 dm-font pink-color mb-2">{t('Budget')}</p>
                          <div class="form-floating wf-input wf-input-101">
                            <input defaultValue={budgetclicked.event_total}
                              //   onChange={(e) => {
                              //   const enteredValue = parseFloat(e.target.value); // Use parseFloat for decimal values
                              //   if (!isNaN(enteredValue) && enteredValue >= 0) {
                              //     settotalBudget(enteredValue);
                              //   }
                              // }} 
                              onChange={(e) => {
                                const enteredValue = parseInt(e.target.value);
                                if (!isNaN(enteredValue) && enteredValue >= 0) {
                                  settotalBudget(enteredValue);
                                } else {
                                  e.target.value = null
                                }
                              }} type="number" class="form-control" id="price-total" placeholder="Total Price" autocomplete="price" required />
                            <label for="price-total">{budgetclicked.user_budget_currency}</label>
                          </div>
                        </div>
                      </div>

                      <div class="modal-footer border-0 justify-content-center gap-2">
                        <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>{t('Cancel')}</button>
                        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => handleSave()} disabled={load1}>{load1 ? (
                          <div className="spinner-border wf-spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <>{t('Confirm')}</>
                        )}</button>
                      </div>

                    </div>
                  </div>
                </>) : modalType == "allocationedit" ? (<>
                  <div className="modal-content" id="allocation-edit">
                    <div class="modal-header border-0 pb-0">
                      <h1 class="font-size-22 dm-font purple-color" id="addbooking">{t('Allocated')} {t('Budget')}</h1>
                      <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                      <div className="row mb-3 gy-3">
                        <div className="col-md-6">
                          <p className="font-size-18 dm-font pink-color mb-2">{t('Event')}</p>
                          <div className="event-list wf-shadow-2 rounded py-3 px-3 white-bg" style={{ borderLeft: `8px solid ${budgetclicked.event_id.event_color}` }}>
                            <p className="font-size-12 color-black mb-0 text-capitalize">{budgetclicked.event_id.event_name}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="font-size-18 dm-font pink-color mb-2">{t('Category')}</p>
                          <div className="d-flex align-items-center gap-2 wf-shadow-2 rounded py-2 px-3 white-bg">
                            <img width="30" src={`${process.env.REACT_APP_IMGURL}${budgetclicked?.budget_category[i]?.category_id.imageName}`} alt="" className="mb-1" />
                            <p className="font-size-12 color-black mb-0 text-capitalize">{budgetclicked?.budget_category[i]?.category_id.name}</p>
                          </div>
                        </div>
                      </div>

                      <div className="wf-range-bar">


                        <div class="form-floating wf-input wf-input-101 mb-3">
                          <input
                            type="number"
                            defaultValue={budgetclicked?.budget_category[i]?.allocated}
                            class="form-control"
                            id="floatingInputtt"
                            placeholder="Allocated Budget"

                            onChange={(e) => {
                              const enteredValue = parseInt(e.target.value);
                              if (!isNaN(enteredValue) && enteredValue >= 0) {
                                const updatedBudgetClicked = {
                                  ...budgetclicked,
                                  budget_category: budgetclicked.budget_category.map((item, index) =>
                                    index === i
                                      ? {
                                        ...item,
                                        allocated: enteredValue,
                                      }
                                      : item
                                  ),
                                };

                                setbudgetclicked(updatedBudgetClicked);
                              }
                            }}
                          />
                          <label for="floatingInputtt">{t('Allocated')} {t('Budget')}</label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">

                      </div>

                      <div class="modal-footer border-0 justify-content-center gap-2">
                        <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>{t('Cancel')}</button>
                        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => EditCategoryAllocation("")} disabled={load1}>{load1 ? (
                          <div className="spinner-border wf-spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <>{t('Confirm')}</>
                        )}</button>
                      </div>

                    </div>


                  </div>
                </>) :

                  modalType == "add-new-category" ? (<>
                    <div className="modal-content" id="add-new-category">
                      <div class="modal-header border-0 pb-0">
                        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('Add')} {t('Your')} {t('Categories')}</h1>
                        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <div class="d-flex gap-2 flex-wrap">
                          {load ? new Array(22).fill(null).map((_, index) => (
                            <CaregoriesButtonSkeleton key={index} />
                          )) : allCategories
                            ?.filter(c =>
                              !budgetclicked?.budget_category?.some(obj => obj.category_id?._id === c?._id)
                            )
                            ?.map(c => (<>
                              <input type="checkbox" checked={selectedcategories?.includes(c?._id)} onClick={() => handleSkillChange(c?._id)} name="cat-select" id={c._id + 4} class="btn-check" />
                              <label class="btn wf-select-btn d-flex flex-column justify-content-center gap-2" style={{ width: '15%', lineHeight: '1.2' }} for={c._id + 4}>
                                <img src={`${process.env.REACT_APP_IMGURL}${c.imageName}`} className="mx-auto d-block color" height="35" />
                                <img src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`} className="mx-auto d-block white" height="35" />
                                {c.name}</label>
                            </>))}
                        </div>
                      </div>
                      <div className="modal-footer border-0 justify-content-center">
                        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={handleSaveCategory} disabled={load1}>{load1 ? (
                          <div className="spinner-border wf-spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <>{t('Save')}</>
                        )}</button>
                      </div>
                    </div>

                  </>) : modalType == "delete-category" ? (<>
                    <div className="modal-content" id="delete-category">
                      <div class="modal-header border-0 pb-0">
                        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('Delete')} {t('Category')}</h1>
                        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body mt-0">
                        <p className="font-size-14 color-black mb-0">{t('Areyousureyouwanttodeletethis')} {t('Category')} {t('item')}.</p>
                      </div>
                      <div class="modal-footer border-0 justify-content-center gap-2">
                        <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>{t('Cancel')}</button>
                        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => handleRemove()}>{t('Delete')}</button>
                      </div>
                    </div>
                  </>) : modalType == "delete-allcategory" ? (<>
                    <div className="modal-content" id="delete-category">
                      <div class="modal-header border-0 pb-0">
                        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">{t('Delete')} {t('Category')}</h1>
                        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body mt-0">
                        <p className="font-size-14 color-black mb-0">{t('Are you sure you want to delete this')} {t('Category')}.</p>
                      </div>
                      <div class="modal-footer border-0 justify-content-center gap-2">
                        <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>{t('Cancel')}</button>
                        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => accountDelete()} disabled={load1}>{load1 ? (
                          <div className="spinner-border wf-spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <>{t('Delete')}</>
                        )}</button>
                      </div>
                    </div>
                  </>) : modalType == "filter" ? (<>
                    <div className="modal-content" id="delete-category">
                      <div class="modal-header border-0 pb-0">
                        <h1 class="font-size-22 dm-font purple-color" id="staticBackdropLabel">Filter</h1>
                        <button type="button" onClick={closeModal} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body mt-0">

                        {profile?.role == "planner" && coupleURLFilter?.length == 0 && (<>
                          <div class="mb-4 row">
                            <label
                              for="staticEmail"
                              class="col-12 font-size-14 align-self-center col-form-label"
                            >
                              {t("Couples")}
                            </label>
                            <div class="col-12 d-flex gap-2 flex-wrap">
                              {load ? (
                                new Array(5)
                                  .fill(null)
                                  .map((_, index) => <ButtonSkeleton key={index} />)
                              ) : (
                                <>
                                  <input
                                    checked={!selectedCouple}
                                    onChange={() => { setselectedCouple(""); setevent([]) }}
                                    type="checkbox"
                                    name="event-select"
                                    id={554}
                                    class="btn-check"
                                  />
                                  <label class="btn wf-select-btn" for={554}>
                                    {t('All')}
                                  </label>

                                  {actualmyEvents?.map((e) => (
                                    <>
                                      <input
                                        checked={selectedCouple == e._id}
                                        onChange={() => setselectedCouple(e._id)}
                                        type="checkbox"
                                        name="event-select"
                                        id={e._id}
                                        class="btn-check"
                                      />
                                      <label
                                        class="btn wf-select-btn"
                                        for={e._id}
                                      // style={{ "--bs-btn-active-bg": ` ${e.event_color}` }}
                                      >
                                        {`${e?.person1?.name || ""} ${e?.person2?.name ? "&" : ""} ${e?.person2?.name || ""}`}
                                      </label>
                                    </>
                                  ))}
                                </>
                              )}
                            </div>
                          </div> </>)}

                        {profile?.role !== "planner" && (<><div class="mb-4 row">
                          <label
                            for="staticEmail"
                            class="col-12 font-size-14 align-self-center col-form-label"
                          >
                            {t("Events")}
                          </label>
                          <div class="col-12 d-flex gap-2 flex-wrap">
                            {load ? (
                              new Array(5)
                                .fill(null)
                                .map((_, index) => <ButtonSkeleton key={index} />)
                            ) : (
                              <>
                                <input
                                  checked={event?.length < 1 ? true : false}
                                  onChange={() => setevent([])}
                                  type="checkbox"
                                  name="event-select"
                                  id={56}
                                  class="btn-check"
                                />
                                <label class="btn wf-select-btn" for={56}>
                                  {t('All')}
                                </label>

                                {EventList?.map((e) => (
                                  <>
                                    <input
                                      checked={event.includes(e.id)}
                                      onChange={() => handleEventChange(e.id)}
                                      type="checkbox"
                                      name="event-select"
                                      id={e.id}
                                      class="btn-check"
                                    />
                                    <label
                                      class="btn wf-select-btn"
                                      for={e.id}
                                      style={{ "--bs-btn-active-bg": ` ${e.color}` }}
                                    >
                                      {e.name}
                                    </label>
                                  </>
                                ))}
                              </>
                            )}
                          </div>
                        </div></>)}

                        {profile?.role == "planner" && coupleURLFilter?.length == 0 && selectedCouple && (<><div class="mb-4 row">
                          <label
                            for="staticEmail"
                            class="col-12 font-size-14 align-self-center col-form-label"
                          >
                            {t("Events")}
                          </label>
                          <div class="col-12 d-flex gap-2 flex-wrap">
                            {load ? (
                              new Array(5)
                                .fill(null)
                                .map((_, index) => <ButtonSkeleton key={index} />)
                            ) : (
                              <>
                                <input
                                  checked={event?.length < 1 ? true : false}
                                  onChange={() => setevent([])}
                                  type="checkbox"
                                  name="event-select"
                                  id={56}
                                  class="btn-check"
                                />
                                <label class="btn wf-select-btn" for={56}>
                                  {t('All')}
                                </label>

                                {actualmyEvents
                                  ?.filter((f) => f._id == selectedCouple && f?.event_id)[0]?.Event?.map((e) => {
                                    console.log('eeeeeeeeeeee', e)
                                    return (
                                      <>
                                        <input
                                          checked={event.includes(e._id)}
                                          onChange={() => handleEventChange(e._id)}
                                          type="checkbox"
                                          name="event-select"
                                          id={e._id}
                                          class="btn-check"
                                        />
                                        <label
                                          class="btn wf-select-btn"
                                          for={e._id}
                                          style={{ "--bs-btn-active-bg": ` ${e.event_color || ""}` }}
                                        >
                                          {e.event_name || ""}
                                        </label>
                                      </>
                                    )
                                  })}
                              </>
                            )}
                          </div>
                        </div></>)}

                        <div class="mb-4 row">
                          <label for="staticEmail" class="col-12 font-size-14 align-self-start col-form-label">{t('Category')}</label>
                          <div class="col-12 d-flex gap-2 flex-wrap">
                            {load ? new Array(22).fill(null).map((_, index) => (
                              <CaregoriesButtonSkeleton key={index} />
                            )) :

                              <>
                                <input checked={category_id?.length < 1} onChange={() => setcategory_id([])} type="checkbox" name="cat-select" id={77} class="btn-check" />
                                <label class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{ width: '11%', lineHeight: '1.2' }} for={77}>
                                  <img src={`${process.env.REACT_APP_IMGURL}all.png`} className="mx-auto d-block color" height="35" />
                                  <img src={`${process.env.REACT_APP_IMGURL}white-all.png`} className="mx-auto d-block white" height="35" />
                                  {t('All')}</label>
                                {allCategories?.map((c) => (
                                  <>
                                    <input checked={category_id?.length > 0 && category_id?.includes(c._id) ? true : false} onChange={() => handleCategoryChange(c._id)} type="checkbox" name="cat-select" id={c.name} class="btn-check" />
                                    <label class="btn wf-select-btn wf-select-cat-btn d-flex flex-column justify-content-center gap-2" style={{ width: '11%', lineHeight: '1.2' }} for={c.name}>
                                      <img src={`${process.env.REACT_APP_IMGURL}${c.imageName}`} className="mx-auto d-block color" height="35" />
                                      <img src={`${process.env.REACT_APP_IMGURL}white-${c.imageName}`} className="mx-auto d-block white" height="35" />
                                      {c.name}</label>
                                  </>
                                ))}
                              </>}
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer border-0 justify-content-center gap-2">
                        <button class="btn wf-btn-2 wf-purple-outline-btn py-2 px-4 rounded-2" onClick={closeModal}>Close</button>
                        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2" onClick={() => { setfiltertoggle(!filtertoggle); closeModal() }}>Apply Filter</button>
                      </div>
                    </div>
                  </>) : null}
            </div>
          </Modal>
        )}




      <section className="wf-budget wf-background px-md-5 pb-5 pt-2">
        <div className="container-fluid">
          {location.pathname === "/dashboard" || location.pathname === "/dashboard-planner" || location.pathname === "/couples-overview" ? null : (
            <>
              <div>
                <h2 className="font-size-26 dm-font purple-color mb-4">{t('Budget')}</h2>

                {!queryParams.get("couple") && !queryParams.get("events") && (
                  <>
                    <div className="row mb-4">
                      <div className="col-lg-4 mb-3 mb-lg-0">
                        <div className="wf-shadow-2 white-bg rounded p-3 h-100 d-flex flex-column justify-content-between">
                          <div className="d-flex justify-content-between mb-2">
                            <div>
                              <p className="color-grey font-size-20 mb-0">Total Budget</p>
                              <p className="purple-color font-size-20 mb-0 fw-bold">
                                {eventBudget?.reduce((sum, obj) => {
                                  const countsSum = obj.event_total;
                                  return sum + countsSum;
                                }, 0)?.toLocaleString('en-US')}
                              </p>
                              <p className="color-black font-size-18 mb-0">
                                {myEvents?.filter((f) => f?.Event?.event_name)?.length || 0} {myEvents?.filter((f) => f?.Event?.event_name)?.length < 2 ? "Event" : "Events"}
                              </p>
                            </div>
                            <img width="75" className="" alt="icon" src="../assets/images/budget-icon-wf.svg" />
                          </div>
                          <div className="w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <p style={{ lineHeight: "normal" }} className="font-size-20 pink-color mb-1 text-start">
                                <b>Spent</b> <br />
                                {eventBudget?.length && eventBudget[0]?.user_budget_currency}  {
                                  eventBudget?.reduce((acc, obj) => acc + obj.event_total - obj.event_total_available, 0)?.toLocaleString('en-US')
                                }
                              </p>
                              <p style={{ lineHeight: "normal" }} className="font-size-20 purple-color mb-1 text-end">
                                <b>Available</b> <br /> {eventBudget?.length && eventBudget[0]?.user_budget_currency} {
                                  eventBudget?.reduce((acc, obj) => acc + obj.event_total_available, 0)?.toLocaleString('en-US')
                                }
                              </p>
                            </div>

                            <div className="d-flex gap-1 align-items-center">
                              <p className="font-size-14 pink-color mb-0">
                                {((eventBudget?.reduce((acc, obj) => acc + obj.event_total - obj.event_total_available, 0) / eventBudget?.reduce((acc, obj) => acc + obj.event_total, 0) * 100) || 0).toFixed(0)}%
                              </p>
                              <div className="progress w-100" style={{ height: "12px", width: "100%" }}>
                                <div className="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${((eventBudget?.reduce((acc, obj) => acc + obj.event_total - obj.event_total_available, 0) / eventBudget?.reduce((acc, obj) => acc + obj.event_total, 0) * 100).toFixed(0) || 0)}%`, backgroundColor: "var(--color4)" }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                              <p className="font-size-14 purple-color mb-0">
                                {((eventBudget?.reduce((acc, obj) => acc + obj.event_total_available, 0) / eventBudget?.reduce((acc, obj) => acc + obj.event_total, 0) * 100) || 0).toFixed(0)}%
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="wf-shadow-2 white-bg rounded p-2 h-100">
                          <div className="row gx-2">
                            <div className="col-md-4">
                              <p className="purple-color font-size-20 mb-0 dm-font">All Events</p>
                              <PieChart
                                data={graphdata}
                                animate={true}
                                labelStyle={{
                                  fontSize: '10px',
                                  fontFamily: 'Arial, sans-serif',
                                  fontWeight: 'bold',
                                }}
                                style={{
                                  width: '100%',
                                  height: '350px',
                                }}
                              />
                            </div>
                            <div className="col-md-8">
                              <div className="d-flex gap-2 flex-column p-2" style={{ height: '400px', overflow: 'auto' }}>
                                {eventBudget?.map((e, index) => (
                                  <div className="col-md-12" key={index}>
                                    <div className="wf-shadow-2 rounded white-bg d-flex flex-column flex-xl-row p-2">
                                      <div className="col-12 col-xl-3 align-self-center">
                                        <h4 className="font-size-18 dm-font mb-1 text-capitalize text-break d-flex flex-column align-items-start">
                                          {e?.event_id?.event_name}
                                          <span className="d-block mt-1" style={{ backgroundColor: `${e.event_id.event_color}`, height: '3px', width: '60px' }}></span>
                                        </h4>
                                      </div>
                                      <div className="col-12 col-xl-3 px-xl-1 align-self-center">
                                        <p className="color-black font-size-14 mb-0">Total Budget</p>
                                        <p className="purple-color font-size-14 mb-0 fw-bold">{e?.user_budget_currency} {e?.event_total?.toLocaleString('en-US')}</p>
                                      </div>
                                      <div className="col-12 col-xl-6">
                                        <div className="w-100">
                                          <div className="d-flex justify-content-between align-items-center mb-0">
                                            <p className="font-size-14 pink-color mb-0 text-start">Spent <b>{e?.user_budget_currency} {(e?.event_total - e?.event_total_available)?.toLocaleString('en-US')}</b></p>
                                            <p className="font-size-14 purple-color mb-0 text-end">Remaining <b>{e?.user_budget_currency} {e?.event_total_available?.toLocaleString('en-US')}</b></p>
                                          </div>
                                          <div className="d-flex gap-1 align-items-center">
                                            <p className="font-size-14 pink-color mb-0">{(((e?.event_total - e?.event_total_available) / e?.event_total) * 100).toFixed(0) || 0}%</p>
                                            <div className="progress w-100" style={{ height: "12px", width: "100%" }}>
                                              <div className="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${(((e?.event_total - e?.event_total_available) / e?.event_total) * 100)}%`, backgroundColor: "var(--color4)" }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <p className="font-size-14 purple-color mb-0">{(((e?.event_total_available / e?.event_total) * 100).toFixed(0)) || 0}%</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-md-6">
                        <p className="purple-color font-size-20 mb-0 dm-font">Categories</p>
                      </div>
                    </div>

                    <div className="wf-shadow-2 rounded white-bg p-3 mb-3">
                      <div className="row gx-2">
                        {categorylistdata?.length > 0 ? categorylistdata?.map((c, index) => (
                          <div key={index} className="col-md-3">
                            <div className="wf-shadow-2 rounded white-bg p-2">
                              <div className="d-flex justify-content-between mb-2">
                                <img style={{ height: 'auto', width: '56px', objectFit: 'contain' }} className="" alt="icon" src={`${process.env.REACT_APP_IMGURL}${c?.imageName}` || "https://via.placeholder.com/50x50"} />
                                <div className="text-end">
                                  <p className="color-grey font-size-16 mb-0 text-end">{c?.length} {c?.length < 2 ? "Booking" : "Bookings"}</p>
                                  <p className="purple-color font-size-20 mb-0 fw-bold d-flex align-items-center gap-1 text-end">
                                    <span className="d-block color-grey font-size-14 fw-medium">Total</span>AED {c?.totalPrice?.toLocaleString('en-US')}
                                  </p>
                                  <span className="font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-uppercase">
                                    <a className="color-white" href={`${process.env.REACT_APP_FrontURL}/suppliers/${c.name}`}>{c?.name}</a>
                                  </span>
                                </div>
                              </div>
                              <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                  <p style={{ lineHeight: "normal" }} className="font-size-16 pink-color mb-1 text-start">
                                    <b>{t('Paid')}</b> <br /> SAR {c?.paid?.toLocaleString('en-US')}
                                  </p>
                                  <p style={{ lineHeight: "normal" }} className="font-size-16 purple-color mb-1 text-end">
                                    <b>Remaining</b> <br /> SAR {c?.due?.toLocaleString('en-US')}
                                  </p>
                                </div>
                                <div className="progress" style={{ height: "12px", width: "100%" }}>
                                  <div className="progress-bar" role="progressbar" aria-label="Example 20px high" style={{ width: `${(c.paid / c.totalPrice) * 100}%`, backgroundColor: "var(--color4)" }} aria-valuenow={`80%`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )) : <NoEventUI name={"Budget"} />}
                      </div>
                    </div>
                    <h2 className="font-size-22 dm-font purple-color mb-2">Budget Payment</h2>
                  </>
                )}
                <div className="row mb-3 gy-3  d-flex justify-content-md-end gap-3 align-items-center">
                  <div className="col-md-6 d-flex gap-4 align-items-center">
                    {eventBudget ? (<div className="flex-grow-1 wf-search-chats wf-search-chats-2">
                      <input onChange={(e) => setsearch(e.target.value)} type="text" className="form-control wf-search-chats-field" placeholder="Search Bookings.." />
                      <Search width="18" height="18" className="wf-search-chats-icon" stroke="var(--color3)" />
                    </div>) : null}
                    {filteredData ? (<div className="d-flex align-items-center gap-2 cursor-pointer"><div className="d-flex align-items-center gap-2 cursor-pointer" onClick={() => viewModal("filter", "", "", "")}>
                      <ListFilter size={22} stroke="var(--color3)" />
                      <p className="font-size-16 purple-color mb-0 fw-medium">{t('Filters')}</p>
                    </div></div>) : null}
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="row gy-1">
            <div className="col-12">
              <div className="wf-shadow-2 rounded white-bg py-2 px-3">
                {filteredData?.some(obj =>
                  obj.budget_category?.some(category =>
                    category?.category_items?.some(item => '_id' in item)
                  )
                ) ? (
                  filteredData?.map((obj) =>
                    obj.budget_category?.map((category) =>
                      category.category_items
                        ?.filter((item) => "_id" in item)
                        .map((c) => (
                          <div className="d-flex flex-wrap justify-content-between align-items-center gap-2 mb-4">
                            <div className="align-self-center" style={{ width: "200px" }}>
                              <p className="font-size-16 mb-0 text-capitalize d-flex flex-column align-items-start w-auto">
                                {c?.user_event?.event_name}
                                <span
                                  className="d-block"
                                  style={{
                                    backgroundColor: `${c?.user_event?.event_color}`,
                                    height: "3px",
                                    width: "80px",
                                    marginTop: "2px",
                                  }}
                                ></span>
                              </p>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="me-2">
                                <p className="font-size-16 color-black mb-0">
                                  {moment(c?.createdAt).format("DD MMM YYYY hh:mm")}
                                </p>
                              </div>
                              <div className="d-flex gap-2 align-items-center" style={{ width: "200px" }}>
                                <img
                                  style={{ height: "auto", width: "32px", objectFit: "contain" }}
                                  alt="icon"
                                  src={
                                    c?.vendor_event?.image
                                      ? `${process.env.REACT_APP_IMGURL}${c?.vendor_event?.image}`
                                      : "https://via.placeholder.com/50x50"
                                  }
                                />
                                <div className="d-flex flex-column align-items-start">
                                  <p className="font-size-16 color-black mb-0">
                                    {c?.vendor_event ? c?.vendor_event?.store_name : c?.title}
                                  </p> 
                                  <div className="d-flex" style={{width:"115px"}}>
                                    <span className="w-100 font-size-10 rounded-1 wf-pink-btn py-1 px-2 text-break text-uppercase">
                                    <a
                                      className="color-white"
                                      href={`${process.env.REACT_APP_FrontURL}/suppliers/${c?.category_id?.name}`}
                                    >
                                      {c?.category_id?.name}
                                    </a>
                                      </span>                                                              
                                    </div>
                                </div>
                              </div>
                            </div>

                            {profile?.role === "planner" && (
                              <div className="" style={{ width: "200px" }}>
                                <div className="wf-up-avatar d-flex align-items-center mb-0">
                                  <div className="avatar-couple-first">
                                    <img
                                      width="57"
                                      height="57"
                                      alt=""
                                      src={c?.user_event?.user_id?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${c?.user_event?.user_id?.person1?.imageName}` : "https://via.placeholder.com/50x50"}
                                      className="rounded-circle avatar"
                                      style={{ border: "3px solid #804099", objectFit: "cover" }}
                                    />
                                  </div>
                                  <div className="avatar-couple-sec me-2" style={{ marginLeft: "-30px" }}>
                                    <img
                                      width="57"
                                      height="57"
                                      alt=""
                                      src={c?.user_event?.user_id?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${c?.user_event?.user_id?.person2?.imageName}` : "https://via.placeholder.com/50x50"}
                                      className="rounded-circle avatar"
                                      style={{ border: "3px solid #EE0E7B", objectFit: "cover" }}
                                    />
                                  </div>
                                  <p className="font-size-16 pink-color mb-0 fw-semibold">
                                    {c?.user_event?.user_id?.person1?.name || "partner"} &{" "}
                                    {c?.user_event?.user_id?.person2?.name || "partner"}
                                  </p>
                                </div>
                              </div>
                            )}

                            

                            <div className="d-flex flex-wrap flex-md-row flex-column gap-3 justify-content-between align-items-md-center align-items-start" style={{ width: "500px" }}>
                              <div className="d-flex gap-1 align-items-center">
                                <Clock12 size={24} stroke="var(--color4)" />
                                <div>
                                  <p className="font-size-16 color-black mb-0">
                                    {!c?.initialDeposit && c?.initialDeposit < 1 ? "One-Time" : "Part-Payment"}
                                  </p>
                                  <p className="font-size-14 color-black mb-0">Payment cycle</p>
                                </div>
                              </div>
                              <div className="d-flex gap-1 align-items-center">
                                <Banknote size={24} stroke="var(--color4)" />
                                <div>
                                  <p className="font-size-16 color-black mb-0">Cash</p>
                                  <p className="font-size-14 color-black mb-0">Payment mode</p>
                                </div>
                              </div>
                              <div>
                                <p className="font-size-16 purple-color mb-0">AED {c?.totalPrice?.toLocaleString("en-US")}</p>
                                <p className="font-size-14 color-black mb-0">Amount</p>
                              </div>
                              {/* <div className="align-self-center cursor-pointer">
                      <ScrollText size={24} stroke="var(--color3)" />
                    </div> */}
                            </div>
                          </div>
                        ))
                    )
                  )
                ) : (
                  <NoEventUI name={"Budget"} />
                )}
              </div>
            </div>
          </div>



        </div>
      </section>
    </>
  )
}

export default Budget