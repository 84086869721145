import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getProfile,
  getEventList,
  getUserEventList,
  editTask,
  addTask,
} from "../../store/slices/userSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";

const EventOverview = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [e, sete] = useState(location?.state?.data);
  const currentDate = new Date().toISOString().split("T")[0];
  const [load, setload] = useState(false);
  const [tasks, settasks] = useState([]);
  const [guests, setguests] = useState([]);
  const [bookings, setbookings] = useState([]);
  const [load1, setload1] = useState(false);
  const [show, setshow] = useState(false);
  const [feature, setFeature] = useState("");



  useEffect(() => {


    // const mergedArr1 = location?.state?.data?.Event?.reduce((acc, curr) => {
    //   return acc.concat(curr.eventGuests);
    // }, []);
    setguests(location?.state?.data?.Event?.eventGuests);

    // const mergedArr2 = location?.state?.data?.Event?.reduce((acc, curr) => {
    //   return acc.concat(curr.eventTasks);
    // }, []);
    settasks(location?.state?.data?.Event?.eventTasks);


    // const mergedArr3 = location?.state?.data?.Event?.reduce((acc, curr) => {
    //   return acc.concat(curr.eventBookings);
    // }, []);
    setbookings(location?.state?.data?.Event?.eventBookings);
  }, [location?.state?.data]);


  const saveBranch = async (t, updatedsubtask, taskIndex) => {
    console.log("trrrr", updatedsubtask);

    try {
      if (myEvents?.length < 1) {
        toast.error("You don't have any event to proceed");
      } else {
        setload1(true);

        console.log("iff", t);
        // return
        const save = await dispatch(
          editTask({
            _id: t._id,
            event_id: t.event_id,
            name: t.name,
            assignedTo: t.assignedTo,
            date: t.date,
            status: t.status,
            subtasks:
              updatedsubtask?.length > 0
                ? JSON.stringify(updatedsubtask)
                : JSON.stringify([]),
            category_id: t.category_id,
          })
        ).unwrap();
        if (updatedsubtask?.length > 0) {
          const updatedTask = { ...t, subtasks: updatedsubtask };
          const updatedTasks = tasks?.map((task, index) =>
            index === taskIndex ? updatedTask : task
          );

          settasks(updatedTasks);
        } else {
          const index = tasks?.findIndex((item) => item._id == t._id);
          if (index !== -1) {
            const newData = [...tasks];
            newData[index].status = newData[index].status == 1 ? 0 : 1;
            settasks(newData);
          }
        }

        setload1(false);
      }
    } catch (rejectedValueOrSerializedError) {
      setload1(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  console.log('location.state.data', location?.state?.data)
console.log("tasksdat",tasks)

  return (
    <>
      <section className='wf-user-dashboard wf-background px-md-5 pb-5 pt-2'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12 col-md-12'>

              {/* Row 1 */}
              <div className='row mb-4'>
                <div className='col-lg-8 mb-3 mb-lg-0'>
                  <div class="d-flex flex-lg-row flex-column gap-3 gap-md-4 align-items-lg-end mb-2">
                    <h2 class="font-size-36 dm-font color-black mb-0" style={{ borderBottom: '3px solid #DB5F6C' }}>Nikkah - Sunday 23 August 2024</h2>
                    <a class="font-size-16 pink-color text-end mb-0" onClick={() => navigate("/event", { state: { _id: { ...e.Event, user_id: e?._id, person1: e?.person1, person2: e?.person2 } } })}><u>Edit Event</u></a>
                  </div>
                </div>
                {/* <div className='col-lg-4 text-md-end'>
                            <div class="d-flex align-items-center justify-content-end gap-4 mb-2">
                                <a class="font-size-20 purple-color  mb-0" href="#">
                                    <i class="bi bi-filter me-1"></i>
                                    Sort by</a>
                                <button type="button" class="btn wf-btn-2 wf-pink-btn py-2 rounded-2">+ Add New</button>
                            </div>
                        </div> */}

              </div>

              <div className='row mb-4'>
                <div className='col-12'>
                  <div className='d-flex flex-sm-row flex-column flex-wrap justify-content-start gap-3 gap-md-5'>
                    <div className='d-flex flex-md-row flex-column gap-2 gap-md-4'>
                      <div className='wf-up-avatar d-flex'>
                        <div className='avatar-couple-first'>
                          <img width="57" height="57" alt="" src={e?.person1 && e?.person1?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.person1?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                        </div>
                        <div className='avatar-couple-sec' style={{ marginLeft: '-30px' }}>
                          <img width="57" height="57" alt="" src={e?.person2 && e?.person2?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.person2?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #EE0E7B', objectFit: 'cover' }} />
                        </div>
                      </div>
                      <div>
                        <h3 className="font-size-16 fw-bold purple-color mb-0">{e?.person1?.name || "partner"}</h3>
                        <p className='font-size-12 fw-normal color-grey mb-0 text-capitalize'><span>{e?.person1?.user_purpose || "partner"}</span></p>
                        <p className='font-size-12 purple-color mb-0'>{e?.person1?.phone || "Not specified"}
                          <br />{e?.person1?.email || "Not specified"}
                        </p>
                      </div>
                      <div>
                        <h3 className="font-size-16 fw-bold pink-color mb-0">{e?.person2?.name || "partner"}{" "} </h3>
                        <p className='font-size-12 fw-normal color-grey mb-0 text-capitalize'><span>{e?.person2?.user_purpose || "partner"}</span></p>
                        <p className='font-size-12 pink-color mb-0'>{e?.person2?.phone || "Not specified"}
                          <br />{e?.person2?.email || "Not specified"}
                        </p>
                      </div>
                    </div>

                    <div className='d-flex flex-md-row flex-column gap-2 gap-md-4'>
                      <div className='wf-up-avatar d-flex'>
                        <div className='avatar-couple-first'>
                          <img width="57" height="57" alt="" src={e?.planner && e?.planner?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.planner?.imageName}` : "https://via.placeholder.com/50x50"} class="rounded-circle avatar" style={{ border: '3px solid #804099', objectFit: 'cover' }} />
                        </div>
                      </div>
                      <div>
                        <h3 className="font-size-16 fw-bold purple-color mb-0">{e?.planner?.name || "Planner"}{" "} <span>(Me)</span></h3>
                        <p className='font-size-12 fw-normal color-grey mb-0 text-capitalize'><span>{e?.planner?.user_purpose || "planner"}</span></p>
                        <p className='font-size-12 purple-color mb-0'>{e?.planner?.phone || "Not specified"}
                          <br />{e?.planner?.email || "Not specified"}
                        </p>
                      </div>
                      <div>
                        <h3 className="font-size-16 fw-bold purple-color mb-0">{e?.Event?.event_city?.name}</h3>
                        <p className='font-size-12 fw-normal color-grey mb-0'><span>{moment(e?.Event?.event_date).format("dddd, D MMMM YYYY")}</span></p>
                        <p className='font-size-12 purple-color mb-0'>{Math.ceil(
                          Math.abs(new Date(e?.Event?.event_date) - new Date()) /
                          (1000 * 60 * 60 * 24)
                        ) || 0}{" "}
                          Days away</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* Row 3 */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <h2 className="font-size-16 fw-normal color-black mb-3">Categories</h2>
                </div>
                <div className='col-12'>
                  <div className='d-flex flex-wrap justify-content-start gap-3 gap-md-5'>
                    {e?.Event?.prefered_services?.map((s) => (<div className="d-flex align-items-center flex-column justify-content-center gap-2">
                      <img height="60" src={s?.details && s?.details?.imageName ? `${process.env.REACT_APP_IMGURL}${s?.details?.imageName}` : "https://via.placeholder.com/50x50"} className="wf-eventscategoryimg" />
                      <p className="font-size-12 fw-medium purple-color mb-0 text-center">{s?._id?.name}</p>
                    </div>))}
                  </div>
                </div>
              </div>
              {/* Row 4 */}
              <div className='row mb-4'>
                <div className='col-12'>
                  <div className='d-flex flex-sm-row flex-column flex-wrap justify-content-start gap-3 gap-md-5'>
                    <div className="d-flex align-items-center gap-2">
                      <img height="50" src="../assets/images/guest-list-icon-img.svg" className="wf-eventscategoryimg" />
                      <div>
                        <h3 className="font-size-20 fw-medium purple-color mb-0">{guests?.reduce(
                          (acc, curr) =>
                            acc +
                            curr.male_count +
                            curr.female_count +
                            curr.child_count,
                          0
                        )}</h3>
                        <p className='font-size-12 fw-normal color-black mb-0'>Expected Guests</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img height="50" src={e?.Event?.prefered_religion?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.Event?.prefered_religion?.imageName}` : "https://via.placeholder.com/50x50"} className="wf-eventscategoryimg" />
                      <div>
                        <h3 className="font-size-20 fw-medium purple-color mb-0">{e?.Event?.prefered_religion?.name}</h3>
                        <p className='font-size-12 fw-normal color-black mb-0'>Religious Preference</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img height="50" src={e?.Event?.prefered_theme?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.Event?.prefered_theme?.imageName}` : "https://via.placeholder.com/50x50"} className="wf-eventscategoryimg" />
                      <div>
                        <h3 className="font-size-20 fw-medium purple-color mb-0">{e?.Event?.prefered_theme?.name}</h3>
                        <p className='font-size-12 fw-normal color-black mb-0'>Wedding Theme</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <img height="50" src={e?.Event?.prefered_ethnics?.imageName ? `${process.env.REACT_APP_IMGURL}${e?.Event?.prefered_ethnics?.imageName}` : "https://via.placeholder.com/50x50"} className="wf-eventscategoryimg" />
                      <div >
                        <h3 className="font-size-20 fw-medium purple-color mb-0">{e?.Event?.prefered_ethnics?.name}</h3>
                        <p className='font-size-12 fw-normal color-black mb-0'>Ethnic Influence</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>

                <div className='col-xxl-6' >
                  <div class="wf-shadow-2 white-bg rounded px-3 py-3 mb-3">


                    <div className='row mb-4'>
                      <div className='col-12'>
                        <div class="d-flex gap-4 align-items-end mb-2">
                          <h2 class="font-size-30 dm-font purple-color mb-0">Tasks</h2>
                          <a class="font-size-14 pink-color mb-0" href="/task-management"><u>View All</u></a>
                        </div>
                      </div>
                    </div>
                    {/* Row 2 */}
                    <div className='row mb-4'>
                      <div className='col-12'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3'>
                          <div class="wf-Totaltask-update">
                            <h3 class="font-size-16 fw-normal purple-color mb-1">
                              Total <span className="fw-bold">{tasks?.length} {tasks?.length > 1 ? "Tasks" : "Task"}</span>
                            </h3>
                            <h3 class="font-size-16 fw-normal purple-color mb-1">
                              Remaining <span className="fw-bold">{tasks?.filter((f) => f?.status === 0)
                                ?.length
                              } {tasks?.filter((f) => f?.status === 0)
                                ?.length > 1 ? "Tasks" : "Task"}</span>
                            </h3>
                          </div>
                          <div className="wf-task-progress-overprogress">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <h4 className="font-size-16 fw-normal  purple-color mb-0">
                                Over Due <span className="fw-bold">{(
                                  (tasks?.filter(
                                    (f) =>
                                      f?.status === 0 &&
                                      moment(new Date()).format("yyyy-mm-dd") < f?.date

                                  )?.length /
                                    Math.max(tasks?.length, 1)) *
                                  100 || 0
                                ).toFixed(0)}%</span>{" "}
                                ({
                                  tasks?.filter(
                                    (f) =>
                                      f?.status === 0 &&
                                      moment(new Date()).format("yyyy-mm-dd") < f?.date
                                  )?.length} Tasks)
                              </h4>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${((tasks?.filter((f) => f?.status === 0 && moment(new Date()).format("yyyy-mm-dd") < f?.date)?.length / Math.max(tasks?.length, 1)) * 100 || 0).toFixed(0)}%` }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                          <div className="wf-task-progress-completed">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <h4 className="font-size-16 fw-normal  purple-color mb-0">
                                Completed <span className="fw-bold">{`${(
                                  (tasks?.filter(
                                    (f) =>
                                      f?.status === 1

                                  )?.length /
                                    Math.max(tasks?.length, 1)) *
                                  100 || 0
                                ).toFixed(0)}`}%</span>{" "}
                                ({`${tasks?.filter(
                                  (f) =>
                                    f?.status === 1

                                )?.length}`} Tasks)
                              </h4>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${((tasks?.filter((f) => f?.status === 1)?.length / Math.max(tasks?.length, 1)) * 100 || 0).toFixed(0)}%` }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mb-4'>
                      {tasks?.map((t, ii) => (
                        <div className="col-12">
                          <div className="wf-shadow-2 white-bg rounded px-2 py-2 mb-3">
                            <div className="row align-items-stretch">
                              <div className="col-md-2">
                                <div className="wf-task-avatar d-flex justify-content-center mb-2">
                                  <img
                                    width="58"
                                    height="59"
                                    alt=""
                                    src={`${process.env.REACT_APP_IMGURL}${t?.taskCategory?.imageName}`}
                                    class="img-fluid"
                                    style={{}}
                                  />
                                </div>
                                <div className="d-flex justify-content-center align-items-center bottom-box mb-2 mb-md-0">
                                  <span className="font-size-8 rounded-1 wf-pink-btn py-1 px-2 text-center text-break text-uppercase">
                                    <a href={`/suppliers/${t?.taskCategory?.name?.toLowerCase()}`} className="color-white">
                                      {t?.taskCategory?.name}
                                    </a>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-7">
                                <h3 className="font-size-14 fw-medium color-black mb-2">
                                  {t.name}
                                </h3>
                                <div className="No-of-per-complete mb-2 d-flex flex-wrap gap-2">
                                  <div className="d-flex gap-2 align-items-center">
                                    {t.subtasks?.length > 0 &&
                                      t.subtasks?.map((st, index) => (
                                        <>
                                          <span
                                            id="pending"
                                            className="rounded-1"
                                            style={{
                                              height: "9px",
                                              width: "27px",
                                              backgroundColor: `${st.completed === 1
                                                ? "green"
                                                : st.completed === 0 &&
                                                  currentDate < st.date
                                                  ? "grey"
                                                  : "red"
                                                }`,
                                            }}
                                          ></span>
                                        </>
                                      ))}

                                    {/* if no subtask then show dash for task */}

                                    {t.subtasks?.length < 1 && (
                                      <>
                                        <span
                                          id="pending"
                                          className="rounded-1"
                                          style={{
                                            height: "9px",
                                            width: "27px",
                                            backgroundColor: `${t.status === 1
                                              ? "green"
                                              : t.status === 0 &&
                                                currentDate < t.date
                                                ? "grey"
                                                : "red"
                                              }`,
                                          }}
                                        ></span>
                                      </>
                                    )}
                                  </div>
                                  <h4 className="font-size-12 fw-medium purple-color mb-0">{`${t?.subtasks?.length > 0
                                    ? (t?.subtasks?.filter(
                                      (st) => st.completed === 1
                                    ).length /
                                      t.subtasks.length) *
                                    100
                                    : t.status == 1
                                      ? 100
                                      : 0
                                    }% Completed`}</h4>
                                </div>

                                <div className="d-flex align-items-center justify-content-between column-gap-3 mb-2">
                                  <div className="wf-event-name">
                                    <p className="font-size-14 fw-medium color-black me-2 mb-0">
                                      {t?.eventDetails?.event_name}
                                    </p>
                                    <span className="eventline nikah"></span>
                                  </div>
                                  <div class="wf-Task-assigned dropdown d-flex align-items-center gap-1 mb-0 me-xxl-5">
                                    <h4 className="font-size-12 fw-medium color-black mb-0">
                                      Assigned to:
                                    </h4>
                                    <p class="font-size-12 purple-color mb-0">
                                      {t?.taskassignedTo?.name
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                        t?.taskassignedTo?.name?.slice(1)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 text-end d-flex flex-md-column justify-content-between">
                                {t.subtasks?.length > 0 && (
                                  <div className="cursor-pointer">
                                    <i
                                      onClick={() => setshow(!show)}
                                      className={`${show
                                        ? "bi bi-chevron-up"
                                        : "bi bi-chevron-down"
                                        } ms-2`}
                                    ></i>
                                  </div>
                                )}

                                {t.subtasks?.length < 1 && (
                                  <div className="purple-color d-flex flex-wrap justify-content-end align-items-center gap-2">
                                    <h4 className="font-size-12   fw-medium purple-color mb-0">
                                      {" "}
                                      Completed{" "}
                                    </h4>
                                    <div>
                                      <input
                                        checked={t?.status == 1 ? true : false}
                                        onChange={() => {
                                          setFeature("");
                                          saveBranch(t, []);
                                        }}
                                        type="checkbox"
                                        class="btn-check"
                                        id={ii}
                                      />
                                      <label class="btn wf-btn-checkk" for={ii}>
                                        <i class="bi bi-check-lg d-flex"></i>
                                      </label>
                                    </div>
                                  </div>
                                )}
                                <div className="wf-taskduedate">
                                  <h3 className="font-size-10 color-black mb-1">
                                    Due Date
                                  </h3>
                                  <p className="font-size-12 purple-color mb-1">
                                    {moment(t.date).format("DD MMM YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {show && t?.subtasks?.length > 0 && (
                              <div className="row wf-tasklist show mt-3">
                                {tasks.map((t, taskIndex) =>
                                  t?.subtasks?.map((st, subtaskIndex) => (
                                    <div
                                      className="col-12"
                                      key={`${taskIndex}-${subtaskIndex}`}
                                    >
                                      <div className="wf-shadow-2 white-bg rounded px-3 py-3 mb-2">
                                        <div className="row align-items-stretch">
                                          <div className="col-md-1">
                                            <h4 className="font-size-20 fw-medium color-grey text-md-center mb-0">
                                              {" "}
                                              {subtaskIndex + 1}{" "}
                                            </h4>
                                          </div>
                                          <div className="col-md-7">
                                            <h3 className="font-size-14 fw-medium pink-color mb-2">
                                              {st?.name}
                                            </h3>
                                          </div>
                                          <div className="col-md-4 text-end">
                                            <div className="purple-color d-flex justify-content-end align-items-center gap-2">
                                              <h4 className="font-size-10 fw-medium purple-color mb-0">
                                                {" "}
                                                Completed{" "}
                                              </h4>
                                              <div>
                                                <input
                                                  onChange={(e) => {
                                                    const newCompletedStatus =
                                                      st.completed === 0
                                                        ? 1
                                                        : 0;
                                                    const updatedSubtasks =
                                                      t.subtasks.map(
                                                        (subtask, index) =>
                                                          index === subtaskIndex
                                                            ? {
                                                              ...subtask,
                                                              completed:
                                                                newCompletedStatus,
                                                              completedDate:
                                                                currentDate,
                                                            }
                                                            : subtask
                                                      );

                                                    saveBranch(
                                                      t,
                                                      updatedSubtasks,
                                                      taskIndex
                                                    );
                                                  }}
                                                  checked={st.completed === 1}
                                                  type="checkbox"
                                                  className="btn-check"
                                                  id={`${taskIndex}-${subtaskIndex}`}
                                                />
                                                <label
                                                  className="btn wf-btn-checkk"
                                                  htmlFor={`${taskIndex}-${subtaskIndex}`}
                                                >
                                                  <i className="bi bi-check-lg d-flex"></i>
                                                </label>
                                              </div>
                                            </div>
                                            <p className="font-size-12 purple-color mb-1">
                                              {moment(st.date).format(
                                                "DD MMM YYYY"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}

                    </div>

                  </div>
                </div>

                <div className="col-xxl-6">
                  <div class="wf-shadow-2 white-bg rounded px-3 py-3 mb-3">
                    {/* Row 1 */}
                    <div className="row mb-4">
                      <div className="col-12">
                        <div class="d-flex gap-4 align-items-end mb-2">
                          <h2 class="font-size-30 dm-font purple-color mb-0">
                            Bookings
                          </h2>
                          <a
                            class="font-size-14 pink-color mb-0"
                            href="/bookings"
                          >
                            <u>View All</u>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Row 2 */}
                    <div className="row mb-4">
                      <div className="col-12">
                        <div className="d-flex flex-column flex-md-row align-items-md-center gap-3 gap-md-5">
                          <div class="wf-Totaltask-update">
                            <h3 class="font-size-16 fw-normal purple-color mb-1">
                              Total{" "}
                              <span className="fw-bold">
                                {bookings?.length}{" "}
                                {bookings?.length < 2 ? "Booking" : "Bookings"}
                              </span>
                            </h3>
                            <h3 className="font-size-16 fw-normal purple-color mb-1">
                              Remaining{" "}
                              <span className="fw-bold">
                                {
                                  bookings?.filter((booking) =>

                                    e?.Event?.preferred_services?.some(
                                      (service) => service?._id === booking?.category_id

                                    )
                                  ).length}

                              </span>
                            </h3>
                          </div>
                          <div className="wf-task-progress-completed">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <h4 className="font-size-16 fw-normal  purple-color mb-0">
                                Confirmed{" "}
                                <span className="fw-bold">
                                  {((bookings?.filter((bo) => bo?.status == 1)
                                    ?.length /
                                    bookings?.length) *
                                    100 || 0).toFixed(0)}
                                  %
                                </span>
                              </h4>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${(bookings?.filter((bo) => bo?.status == 1)
                                    ?.length /
                                    bookings?.length) *
                                    100
                                    }%`,
                                }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <BookingsContent /> */}
                    <div className="row mb-4">
                      <div className="col-12">
                        {bookings?.map((b) => (
                          <div className="d-flex flex-column flex-md-row align-items-md-center wf-shadow-2 white-bg rounded overflow-hidden mb-3">
                            <div className="wf-bookingimgcontaner">
                              <a className="linkedimge" href={`/suppliers/${b?.category_id?.name?.toLowerCase()}`}>
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_IMGURL}${b?.category_id?.imageName}`}
                                  className="wf-bookingimgcontaner-image"
                                />
                              </a>
                              <div className="d-flex justify-content-between align-items-center bottom-box">
                                <span className="w-100 font-size-8 rounded-1 wf-pink-btn py-1 px-2 text-center text-break text-uppercase">
                                  <a href={`/suppliers/${b?.category_id?.name?.toLowerCase()}`} className="color-white">
                                    {b?.category_id?.name}
                                  </a>
                                </span>
                              </div>
                            </div>
                            <div className="px-2 py-2 w-100">
                              <div className="row">
                                <div className="col-md-9">
                                  <h3 className="font-size-16 fw-bold  color-black mb-2">
                                    {b?.title}
                                  </h3>
                                  <h3 className="font-size-12 color-black mb-2">
                                    {b?.user_event?.event_city?.name}
                                  </h3>
                                  <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between gap-3 mb-2">
                                    <div className="wf-event-name mb-1 mb-md-0">
                                      <p className="font-size-14 fw-medium color-black me-2 mb-0">
                                        {b?.user_event?.name}
                                      </p>
                                      <span className="eventline nikah"></span>
                                    </div>
                                    <div className="wf-payment-progress">
                                      <div className="d-flex justify-content-between align-items-center gap-5 mb-1">
                                        <h4 className="font-size-12 fw-normal  pink-color mb-0">
                                          Paid AED {b.paid}
                                        </h4>
                                        <h4 className="font-size-12 fw-normal text-end color-grey mb-0">
                                          Due AED {b.due}
                                        </h4>
                                      </div>
                                      <div className="progress">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{
                                            width: `${(b.paid / b.totalPrice) * 100
                                              }%`,
                                            backgroundColor: "#FF74B8",
                                          }}
                                          aria-valuenow="50"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                        ></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 text-end d-flex flex-md-column justify-content-between">
                                  <div className="purple-color d-flex justify-content-end align-items-center gap-2">
                                    <h4 className="font-size-12   fw-medium purple-color mb-0">
                                      {" "}
                                      {b.status === 1 && <>{t("Confirmed")}</>}
                                      {b.status === 2 && <>{t("Pending")}</>}
                                      {b.status === 3 && (
                                        <>{t("Completed")}</>
                                      )}{" "}
                                    </h4>
                                    {/* <div>
                                      <input
                                        type="checkbox"
                                        class="btn-check"
                                        id="1234"
                                      />
                                      <label
                                        class="btn wf-btn-checkk"
                                        for="1234"
                                      >
                                        <i class="bi bi-check-lg d-flex"></i>
                                      </label>
                                    </div> */}
                                  </div>
                                  <div className="wf-bookingtotal">
                                    <h3 className="font-size-10 fw-medium color-black mb-1">
                                      Total Amount
                                    </h3>
                                    <p className="font-size-12 purple-color mb-1">
                                      AED {b?.totalPrice}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div className='row mb-4'>
                <div className="col-xxl-6">
                  <div class="wf-shadow-2 white-bg rounded px-3 py-3 mb-3">
                    <div className="row mb-4">
                      <div className="col-12">
                        <div class="d-flex gap-4 align-items-end mb-2">
                          <h2 class="font-size-30 dm-font purple-color mb-0">
                            Guests
                          </h2>
                          <a
                            class="font-size-14 pink-color mb-0"
                            href="/guest-list"
                          >
                            <u>View All</u>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* Row 2 */}
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3">
                          <div class="wf-Totaltask-update">
                            <h3 class="font-size-16 fw-normal purple-color mb-1">
                              Total{" "}
                              <span className="fw-bold">
                                {guests?.reduce(
                                  (acc, curr) =>
                                    acc +
                                    curr.male_count +
                                    curr.female_count +
                                    curr.child_count,
                                  0
                                )}
                              </span>
                            </h3>
                            <h3 className="font-size-16 fw-normal purple-color mb-1">
                              Adults{" "}
                              <span className="fw-bold">
                                {guests?.reduce(
                                  (acc, curr) => acc + curr.male_count,
                                  0
                                )}
                              </span>{" "}
                              (
                              {(
                                (guests?.reduce(
                                  (acc, curr) => acc + curr.male_count,
                                  0
                                ) /
                                  guests?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      curr.male_count +
                                      curr.female_count +
                                      curr.child_count,
                                    0
                                  )) *
                                100 || 0
                              ).toFixed(0)}
                              %)
                            </h3>
                          </div>
                          <div className="wf-invited-progressBrideGroom">
                            <div className="d-flex justify-content-between align-items-center gap-5 mb-1">
                              <h4 className="font-size-16 fw-normal  pink-color mb-0">
                                Bride{" "}
                                <span className="fw-bold">
                                  {guests
                                    ?.filter((g) => g.guest_of == "bride")
                                    ?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        curr.male_count +
                                        curr.female_count +
                                        curr.child_count,
                                      0
                                    )}
                                </span>
                              </h4>
                              <h4 className="font-size-16 fw-normal text-end color-black mb-0">
                                Groom{" "}
                                <span className="fw-bold">
                                  {guests
                                    ?.filter((g) => g.guest_of == "groom")
                                    ?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        curr.male_count +
                                        curr.female_count +
                                        curr.child_count,
                                      0
                                    )}
                                </span>
                              </h4>
                            </div>
                            <div className="d-flex justify-content-between align-items-center gap-2 mb-1">
                              <h4 className="font-size-16 fw-normal  pink-color mb-0">
                                {(
                                  (guests
                                    ?.filter((g) => g.guest_of == "bride")
                                    ?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        curr.male_count +
                                        curr.female_count +
                                        curr.child_count,
                                      0
                                    ) /
                                    guests?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        curr.male_count +
                                        curr.female_count +
                                        curr.child_count,
                                      0
                                    )) *
                                  100 || 0
                                ).toFixed(0)}
                                %
                              </h4>
                              <div className="progress">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: "50%" }}
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <h4 className="font-size-16 fw-normal  color-black mb-0">
                                {(
                                  (guests
                                    ?.filter((g) => g.guest_of == "groom")
                                    ?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        curr.male_count +
                                        curr.female_count +
                                        curr.child_count,
                                      0
                                    ) /
                                    guests?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        curr.male_count +
                                        curr.female_count +
                                        curr.child_count,
                                      0
                                    )) *
                                  100 || 0
                                ).toFixed(0)}
                                %
                              </h4>
                            </div>
                          </div>
                          <div className="wf-invited-progress">
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <h4 className="font-size-16 fw-normal  purple-color mb-0">
                                Invited{" "}
                                <span className="fw-bold">
                                  {(
                                    (
                                      e?.Event?.invitedGuests
                                        ?.map(
                                          (i) =>
                                            guests?.find(
                                              (f) => f._id === i.guest_id
                                            )?.male_count +
                                            guests?.find(
                                              (f) => f._id === i.guest_id
                                            )?.female_count +
                                            guests?.find(
                                              (f) => f._id === i.guest_id
                                            )?.child_count
                                        )
                                        .reduce((acc, curr) => acc + curr, 0) /
                                      guests?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          curr.male_count +
                                          curr.female_count +
                                          curr.child_count,
                                        0
                                      )) *
                                    100 || 0
                                  ).toFixed(0)}
                                  %
                                </span>

                              </h4>
                            </div>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                  width: `${(
                                    (
                                      e?.Event?.invitedGuests
                                        ?.map(
                                          (i) =>
                                            guests?.find(
                                              (f) => f._id === i.guest_id
                                            )?.male_count +
                                            guests?.find(
                                              (f) => f._id === i.guest_id
                                            )?.female_count +
                                            guests?.find(
                                              (f) => f._id === i.guest_id
                                            )?.child_count
                                        ).reduce((acc, curr) => acc + curr, 0) /
                                      guests?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          curr.male_count +
                                          curr.female_count +
                                          curr.child_count,
                                        0
                                      )) *
                                    100 || 0
                                  ).toFixed(0)}%`
                                }}
                                aria-valuenow="50"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row wf-Guestlist show mt-3">
                      <div className="col-12">
                        <div className="wf-shadow-2 white-bg rounded px-3 py-3 mb-2">
                          <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-md-between gap-3 gap-md-4">
                            <div className="wf-event-name">
                              <p className="font-size-14 fw-medium color-black me-2 mb-0">
                                {e?.Event?.event_name}
                              </p>
                              <span className="eventline nikah"></span>
                            </div>
                            <div className="wf-payment-progress flex-grow-1">
                              <div className="d-flex justify-content-between align-items-center gap-5 mb-1">
                                <h4 className="font-size-12 fw-normal  pink-color mb-0">
                                  Bride{" "}
                                  <span className="fw-bold">
                                    {
                                      e?.Event?.eventGuests.filter(
                                        (guest) => guest.guest_of === "bride"
                                      )?.length
                                    }
                                  </span>
                                </h4>
                                <h4 className="font-size-12 fw-normal text-end color-black mb-0">
                                  Groom{" "}
                                  <span className="fw-bold">
                                    {
                                      e?.Event?.eventGuests.filter(
                                        (guest) => guest.guest_of === "groom"
                                      )?.length
                                    }
                                  </span>
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between align-items-center gap-2 mb-1">
                                <h4 className="font-size-12 fw-normal  pink-color mb-0">
                                  {((e?.Event?.eventGuests.filter(
                                    (guest) => guest.guest_of === "bride"
                                  )?.length /
                                    e?.Event?.eventGuests?.length) *
                                    100 || 0).toFixed(0)}
                                  %
                                </h4>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width: `${((e?.Event?.eventGuests.filter(
                                        (guest) => guest.guest_of === "bride"
                                      )?.length /
                                        e?.Event?.eventGuests?.length) *
                                        100 || 0).toFixed(0)}%`
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <h4 className="font-size-12 fw-normal  color-black mb-0">
                                  {((e?.Event?.eventGuests.filter(
                                    (guest) => guest.guest_of === "groom"
                                  )?.length /
                                    e?.Event?.eventGuests?.length) *
                                    100 || 0).toFixed(0)}
                                  %
                                </h4>
                              </div>
                            </div>
                            <div className="wf-invited-progress flex-grow-1">
                              <div className="d-flex justify-content-between align-items-center mb-1">
                                <h4 className="font-size-12 fw-normal  purple-color mb-0">
                                  Invited{" "}
                                  <span className="fw-bold">
                                    {e?.Event?.invitedGuests
                                      ?.map((m) => {
                                        const guest = e?.Event?.eventGuests?.find(
                                          (f) => f._id === m.guest_id
                                        );
                                        return guest
                                          ? guest.male_count +
                                          guest.female_count +
                                          guest.child_count
                                          : 0;
                                      })
                                      .reduce((acc, count) => acc + count, 0)}
                                  </span>
                                </h4>
                                <h4 className="font-size-10 fw-normal text-end color-black mb-0">
                                  Total Guests{" "}
                                </h4>
                              </div>
                              <div className="d-flex justify-content-between align-items-center gap-2 mb-1">
                                <h4 className="font-size-12 fw-normal  purple-color mb-0">
                                  {(e?.Event?.invitedGuests
                                    ?.map((m) => {
                                      const guest = e?.Event?.eventGuests?.find(
                                        (f) => f._id === m.guest_id
                                      );
                                      return guest
                                        ? guest.male_count +
                                        guest.female_count +
                                        guest.child_count
                                        : 0;
                                    })
                                    .reduce((acc, count) => acc + count, 0) /
                                    e?.Event?.invitedGuests
                                      ?.map((m) => {
                                        const guest = e?.Event?.eventGuests?.find(
                                          (f) => f._id === m.guest_id
                                        );
                                        return guest
                                          ? guest.male_count +
                                          guest.female_count +
                                          guest.child_count
                                          : 0;
                                      })
                                      .reduce(
                                        (acc, count) => acc + count,
                                        0
                                      )) *
                                    100 || 0}
                                  %
                                </h4>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      width: `${(((e?.Event?.invitedGuests
                                        ?.map((m) => {
                                          const guest = e?.Event?.eventGuests?.find(
                                            (f) => f._id === m.guest_id
                                          );
                                          return guest
                                            ? guest.male_count +
                                            guest.female_count +
                                            guest.child_count
                                            : 0;
                                        })
                                        .reduce((acc, count) => acc + count, 0) /
                                        e?.Event?.invitedGuests
                                          ?.map((m) => {
                                            const guest = e?.Event?.eventGuests?.find(
                                              (f) => f._id === m.guest_id
                                            );
                                            return guest
                                              ? guest.male_count +
                                              guest.female_count +
                                              guest.child_count
                                              : 0;
                                          })
                                          .reduce(
                                            (acc, count) => acc + count,
                                            0
                                          )) *
                                        100).toFixed || 0)}}%`
                                    }}


                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <h4 className="font-size-12 fw-normal  purple-color mb-0">
                                  {e?.Event?.eventGuests?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      curr.male_count +
                                      curr.female_count +
                                      curr.child_count,
                                    0
                                  )}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='col-xxl-6' >
                  <div class="wf-shadow-2 white-bg rounded px-3 py-3 mb-3">
                    <div className='row mb-4'>
                      <div className='col-12'>
                        <div class="d-flex gap-4 align-items-end mb-2">
                          <h2 class="font-size-30 dm-font purple-color mb-0">Budget</h2>
                          <a class="font-size-14 pink-color mb-0" href="/budget"><u>View All</u></a>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12'>
                        <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-md-between gap-3 gap-md-5'>
                          <div class="wf-Totaltask-update">
                            <h3 class="font-size-16 fw-normal purple-color mb-1">Total <span className='fw-bold'>{e?.Event?.eventBudget?.user_budget_currency} {e?.Event?.eventBudget?.event_total || 0}</span></h3>
                          </div>
                          <div className='wf-payment-progress'>
                            <div className='d-flex justify-content-between align-items-center gap-5 mb-1'>
                              <h4 className="font-size-16 fw-normal  pink-color mb-0">Spent  <span className='fw-bold'>{e?.Event?.eventBudget?.user_budget_currency} {e?.Event?.eventBudget?.event_total - e?.Event?.eventBudget?.event_total_available}</span></h4>
                              <h4 className="font-size-16 fw-normal text-end color-black mb-0">Remaining <span className='fw-bold'>{e?.Event?.eventBudget?.user_budget_currency} {e?.Event?.eventBudget?.event_total_available}</span></h4>
                            </div>
                            <div className='d-flex justify-content-between align-items-center gap-2 mb-1'>
                              <h4 className="font-size-16 fw-normal  pink-color mb-0">{(isNaN(((e?.Event?.eventBudget?.event_total - e?.Event?.eventBudget?.event_total_available) / e?.Event?.eventBudget?.event_total * 100).toFixed(0)) ? 0 : ((e?.Event?.eventBudget?.event_total - e?.Event?.eventBudget?.event_total_available) / e?.Event?.eventBudget?.event_total * 100).toFixed(0)) || 0}%</h4>
                              <div className="progress" style={{ height: "12px", width: "100%" }}>
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${((((e?.Event?.eventBudget?.event_total - e?.Event?.eventBudget?.event_total_available) / e?.Event?.eventBudget?.event_total) * 100 || 0).toFixed(0))}%` }}
                                  aria-valuenow="50"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                              <h4 className="font-size-16 fw-normal  color-black mb-0">{(isNaN(((e?.Event?.eventBudget?.event_total_available) / e?.Event?.eventBudget?.event_total * 100).toFixed(0)) ? 0 : ((e?.Event?.eventBudget?.event_total_available) / e?.Event?.eventBudget?.event_total * 100).toFixed(0)) || 0}%</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EventOverview