import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getProfile, getEventList } from "../../store/slices/userSlice";
import moment from "moment";
import NoEventUI from '../UserDashboard/noEventUI';
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

const EventList = () => {
  const { t } = useTranslation();
  const profile = useSelector(getProfile);
  const myEvents = useSelector(getEventList);
  const navigate = useNavigate();

  const [filteredEvents, setfilteredEvents] = useState(myEvents);
  const [search, setsearch] = useState("");

  useEffect(() => {
    setfilteredEvents(myEvents);
  }, [myEvents]);



  useEffect(() => {
    if (search.trim() === "") {
      setfilteredEvents(myEvents);
    } else {
      const filteredEvents = myEvents?.filter((event) =>

        event?.Event && event.Event.event_name?.toLowerCase().includes(search?.toLowerCase())

      );
      setfilteredEvents(filteredEvents);
    }
  }, [myEvents, search]);

  console.log('filteredEvents', filteredEvents)

  return (
    <>
      <section className="wf-user-dashboard wf-background px-md-5 pb-5 pt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {/* Row 1 */}
              <div className="row mb-4">
                <div className="col-md-8">
                  <div class="mb-2">
                    <h2 class="font-size-36 dm-font purple-color mb-0">
                      Events
                    </h2>
                  </div>
                </div>
                <div className="col-md-4 text-md-end">
                  <div class="d-flex align-items-center justify-content-end gap-4 mb-2">
                    {/* <a class="font-size-20 purple-color  mb-0" href="#">
                      <i class="bi bi-filter me-1"></i>
                      Sort by
                    </a> */}
                    <button
                      type="button"
                      class="btn wf-btn-2 wf-pink-btn py-2 rounded-2"
                      onClick={() => {
                        navigate("/event");
                      }}
                    >
                      + Add New
                    </button>
                  </div>
                </div>
              </div>
              {/* Row 2*/}
              {filteredEvents?.every(event => event?.event_id) && (<div className="row mb-4">
                <div className="col-md-6">
                  <div class="mb-2">
                    <h2 class="font-size-30 dm-font pink-color mb-0">Search</h2>
                  </div>
                  <div class="input-group recent-couples-search mb-1 wf-shadow-2 rounded">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Couples or Events..."
                      onChange={(e) => setsearch(e.target.value)}
                    />
                    <button
                      class="input-group-text purple-color"
                      id="serach-couples"
                    >
                      <i class="bi bi-search"></i>
                    </button>
                  </div>
                </div>
                <div className="col-md-6 text-md-end"></div>
              </div>)}
              {/* Row 3 */}
              <div className="row mb-4">
                <div className="col-12">
                  <div className="wf-shadow-2 white-bg rounded px-3 py-3 row-gap-3 d-flex flex-column">
                    {filteredEvents ? filteredEvents?.filter(item => item.Event && '_id' in item.Event)?.map((e) => (
                      <div className="d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-md-center gap-2">
                        <div className="d-flex" style={{ width: "150px" }}>
                          <div className="wf-event-name">
                            <p className="font-size-16 fw-bold color-black me-3 mb-0 text-truncate">
                              {e?.Event?.event_name}
                            </p>
                            <span className="eventline nikah"></span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3" style={{ width: "250px" }}>
                          <div className="wf-up-avatar d-flex mb-0">
                            <div className="avatar-couple-first">
                              <img
                                width="57"
                                height="57"
                                alt=""
                                src={
                                  e.person1 && e?.person2?.imageName ?
                                    `${process.env.REACT_APP_IMGURL}${e?.person1?.imageName}` :
                                    "https://via.placeholder.com/50x50"
                                }
                                class="rounded-circle avatar"
                                style={{
                                  border: "3px solid #804099",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div
                              className="avatar-couple-sec"
                              style={{ marginLeft: "-30px" }}
                            >
                              <img
                                width="57"
                                height="57"
                                alt=""
                                src={
                                  e.person2 && e?.person2?.imageName ?
                                    `${process.env.REACT_APP_IMGURL}${e?.person2?.imageName}` :
                                    "https://via.placeholder.com/50x50"
                                }
                                class="rounded-circle avatar"
                                style={{
                                  border: "3px solid #EE0E7B",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </div>
                          <h3 className="font-size-14 fw-medium color-black mb-1 text-break">
                            <span className="purple-color">
                              {e?.person1?.name || ""}
                            </span>{" "}
                            &{" "}
                            <span className="pink-color">
                              {" "}
                              {e?.person2?.name || "partner"}
                            </span>
                          </h3>
                        </div>
                        <div className="d-flex gap-4 text-break" style={{ width: "300px" }}>
                          <div className="wf-eventslistdate">
                            <p className="font-size-12 color-black mb-1">
                              {moment(e?.Event?.event_date).format(
                                "dddd, D MMMM YYYY"
                              )}
                            </p>
                          </div>
                          <div className="wf-eventslistlocation">
                            <p className="font-size-12 color-black mb-1">
                              {e?.Event?.event_city?.name}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-between align-items-center gap-2 gap-md-3 text-center" style={{ maxWidth: "400px", width: "100%" }}>
                          <div className="wf-couple-update">
                            <h3 className="font-size-16 fw-medium color-black mb-1">
                              {e?.Event?.eventTasks?.length}
                            </h3>
                            <p className="font-size-12 pink-color mb-1">
                              <u>Tasks</u>
                            </p>
                          </div>
                          <div className="wf-couple-update">
                            <h3 className="font-size-16 fw-medium color-black mb-1">
                              AED {e?.Event?.eventBudget?.event_total || 0}
                            </h3>
                            <p className="font-size-12 pink-color mb-1">
                              <u>Budget</u>
                            </p>
                          </div>
                          <div className="wf-couple-update">
                            <h3 className="font-size-16 fw-medium color-black mb-1">
                              {" "}
                              {e?.Event?.eventGuests?.length &&
                                e?.Event?.eventGuests?.reduce(
                                  (total, guest) =>
                                    total +
                                    (guest.male_count || 0) +
                                    (guest.female_count || 0) +
                                    (guest.child_count || 0),
                                  0
                                )}
                            </h3>
                            <p className="font-size-12 pink-color mb-1">
                              <u>Guests</u>
                            </p>
                          </div>
                          <div className='wf-couple-update'>
                            <h3 className="font-size-16 fw-medium color-black mb-1">{e?.Event?.eventFavourites?.length}</h3>
                            <p className="font-size-12 pink-color mb-1"><u>Shortlist</u></p>
                          </div>
                          <div className="wf-couple-update">
                            <h3 className="font-size-16 fw-medium color-black mb-1">
                              {e?.Event?.eventBookings?.length || 0}
                            </h3>
                            <p className="font-size-12 pink-color mb-1">
                              <u>Booking</u>
                            </p>
                          </div>
                        </div>
                        <div className='d-flex gap-4'>
                          {/* <div className='wf-couplenotification'>
                                            <div className='custom-dropdown notification'>
                                                <a class="wf-notifymessages" href="#">
                                                    <i class="far fa-bell icon-notifications"></i>
                                                    <span class="number">5</span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className='wf-couplemessage'>
                                            <div className='custom-dropdown notification'>
                                                <a class="wf-notifymessages" href="#">
                                                    <i class="far fa-envelope icon-notifications"></i>
                                                    <span class="number">5</span>
                                                </a>
                                            </div>
                                        </div> */}
                          <div onClick={() => navigate("/event-overview", { state: { data: e } })} className='color-grey cursor-pointer'>
                            <i class="bi bi-chevron-right"></i>
                          </div>
                        </div>
                      </div>
                    )) : <NoEventUI name={"Events"} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventList;
